export default (function() {

	function init(select) {
		if (select.length > 0) {
			$(select).on('change', e => {
				const _categoryUrl = $(e.currentTarget).val();

				if (_categoryUrl) {
					window.location = _categoryUrl;
				}
			});
		}
	}

	return {
		init,
	};
})();