import 'slick-carousel';
import 'sass-to-js/js/dist/sass-to-js.min.js';


export default (function() {
	let _$carousel;
	let _$nav;
	let _breakpoints;

	function init(carousel) {
		_$carousel   = carousel;
		_$nav        = carousel.siblings('.js-carousel-nav');
		_breakpoints = $('.js-breakpoint-sniffer').sassToJs({pseudoEl: '::after', cssProperty: 'content'});

		if (_$carousel.length > 0) {
			initSlider(_$carousel, _$nav);
		}
	}

	function initSlider(carousel, nav) {
		let _$counter = _$nav.find('.js-carousel-counter');

		$(carousel).on('init', function(event, slick){
			setCounter(_$counter, slick.currentSlide, slick.slideCount);
		});
		carousel.slick({
			lazyLoad      : 'ondemand',
			slidesToShow  : 4,
			slidesToScroll: 1,
			cssEase       : 'cubic-bezier(0.15, 0, 0, 1)',
			infinite      : true,
			speed         : 500,
			dots          : false,
			fade          : false,
			appendArrows  : nav,
			prevArrow   : '<a class="slick-prev slick-arrow" href="#"><i class="icon icon-chevron-left" aria-hidden="true"></i></a>',
			nextArrow   : '<a class="slick-next slick-arrow" href="#"><i class="icon icon-chevron-right" aria-hidden="true"></i></a>',
			responsive    : [
			{
				breakpoint: parseInt(_breakpoints.xl, 10),
				settings  : {
					slidesToShow: 3
				}
			},
			{
				breakpoint: parseInt(_breakpoints.lg, 10),
				settings  : {
					slidesToShow: 2
				}
			},
			{
				breakpoint: parseInt(_breakpoints.md, 10),
				settings  : {
					slidesToShow: 1
				}
			}
			]
		});
		$(carousel).on('beforeChange', function(event, slick, currentSlide, nextSlide){
			setCounter(_$counter, nextSlide, slick.slideCount);
		});
	}
	function setCounter(el, count, total) {
		$(el).text(`${count + 1} / ${total}`);
	}
	return {
		init,
	};
})();