/* global wc_cart_params */
import Modal from 'modules/Modal';
import urlParameters from 'utils/urlParameters';


export default (function() {

	function init() {
		Modal.init($('.js-open-coupon'), $('#coupon-code'));

		const $quantity        = $('.quantity input[type=number]');
		const $applyCoupon     = $('#coupon-code .js-apply-coupon');
		const $couponInput     = $('#coupon-code .js-coupon-code');
		const $cart            = $('.js-cart-wrap');
		const $cartCollaterals = $('.js-cart-collaterals');
		const $cartTotals      = $cartCollaterals.find('.js-cart-totals');
		const $removeProduct   = $('.js-remove-product');

		// wc_cart_params is required to continue, ensure the object exists
		if (typeof wc_cart_params === 'undefined') {
			return false;
		}

		// Update Product Quantity
		$quantity.on('change', (e) => {
			const qty         = $(e.currentTarget).val();
			const currentVal  = parseFloat(qty);
			const item_hash   = $(e.currentTarget).attr('name').replace(/cart\[([\w]+)\]\[qty\]/g, "$1");
			const $subtotal   = $(e.currentTarget).closest('.js-cart-item').find('.woocommerce-Price-amount');
			const $inputQty   = $(e.currentTarget).closest('.js-quantity');

			// Update Cart Product Subtotal
			$.ajax({
				url : wc_cart_params.ajax_url,
				type: 'post',
				data: {
					action  : 'update_product_quantity',
					security: window.theme.ajax_nonce,
					quantity: currentVal,
					hash    : item_hash
				},
				dataType: 'html',
				beforeSend: () => {
					$inputQty.addClass('is-loading');
				},
				success: (data) => {
					$inputQty.removeClass('is-loading');
					$subtotal.replaceWith(data);

					// Update Cart Totals
					$.ajax({
						url : wc_cart_params.ajax_url,
						type: 'post',
						data: {
							action: 'update_cart_totals',
							security: window.theme.ajax_nonce,
							quantity: currentVal,
							hash: item_hash
						},
						dataType: 'html',
						beforeSend: () => {
							$cartCollaterals.addClass('is-loading');
						},
						success: (data) => {
							$cartCollaterals.removeClass('is-loading');
							$cartTotals.replaceWith(data);
							init();

							// Update Mini Cart
							$.ajax({
								url : wc_cart_params.ajax_url,
								type: 'post',
								data: {
									action: 'update_mini_cart',
									security: window.theme.ajax_nonce,
									quantity: currentVal,
									hash: item_hash
								},
								dataType: 'html',
								success: (data) => {
									$('.js-cart-mini').replaceWith(data);
								},
								error: (jqXHR, error) => {
									console.log(jqXHR, error);
								}
							});
						},
						error: (jqXHR, error) => {
							console.log(jqXHR, error);
							$cartCollaterals.removeClass('is-loading');
						}
					});
				},
				error: (jqXHR, error) => {
					console.log(jqXHR, error);
					$cartCollaterals.removeClass('is-loading');
				}
			});

			return false;
		});

		// Remove Product
		$removeProduct.on('click', (e) => {
			const remove_url = $(e.currentTarget).attr('href');
			const item_hash  = urlParameters.getUrlParameter(remove_url, 'remove_item');
			const $cartItem  = $(e.currentTarget).closest('.js-cart-item');

			$.ajax({
				url : wc_cart_params.ajax_url,
				type: 'post',
				data: {
					action: 'product_remove',
					security: window.theme.ajax_nonce,
					hash: item_hash
				},
				dataType: 'html',
				beforeSend: () => {
					$cart.addClass('is-loading');
				},
				success: (data) => {
					$cart.removeClass('is-loading');

					// Check if it's last cart item
					if ($cartItem.siblings('.js-cart-item').length > 0) {
						$cartItem.remove();
						$cartTotals.replaceWith(data);
						init();
					} else {
						$cart.find('div.woocommerce').replaceWith(data);
					}

					// Update Mini Cart
					$.ajax({
						url : wc_cart_params.ajax_url,
						type: 'post',
						data: {
							action  : 'update_mini_cart',
							security: window.theme.ajax_nonce,
							quantity: 0,
							hash    : item_hash
						},
						dataType: 'html',
						success: (data) => {
							$('.js-cart-mini').replaceWith(data);
						},
						error: (jqXHR, error) => {
							console.log(jqXHR, error);
						}
					});
				},
				error: (jqXHR, error) => {
					console.log(jqXHR, error);
					$cartCollaterals.removeClass('is-loading');
				}
			});

			return false;
		});

		// Apply coupon
		$applyCoupon.on('click', () => {
			const couponCode = $couponInput.val();

			if (couponCode) {
				$.ajax({
					url : wc_cart_params.ajax_url,
					type: 'post',
					data: {
						action: 'apply_coupon',
						security: window.theme.ajax_nonce,
						coupon: couponCode
					},
					dataType: 'json',
					beforeSend: () => {
						$cartCollaterals.addClass('is-loading');
						$('#coupon-code').modal('hide');
						$('body').removeClass('has-overlay');
					},
					success: (data) => {
						if (data.notice) {
							$(data.notice).insertBefore($('.b-cart .woocommerce'));
						}

						if (data.cart) {
							$cartTotals.replaceWith(data.cart);
						}

						$cartCollaterals.removeClass('is-loading');
						init();
					},
					error: (jqXHR, error) => {
						console.log(jqXHR, error);
						$cartCollaterals.removeClass('is-loading');
					}
				});

				return false;
			}
		})
	}

	return {
		init,
	};
})();