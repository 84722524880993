export default (function() {
	let docHeight;

	function init() {
		onResize();
		$(window).on('resize touchend', () => {
			onResize();
		});
	}

	function onResize() {
		// $(window).innerHeight() is incorrect on Android
		docHeight = window.innerHeight;
		document.documentElement.style.setProperty('--qwic-app-height', `${docHeight}px`);
	}

	return {
		init,
		onResize
	};
})();