export default (function() {

	function init() {
		const $incrBtn = $('.js-quantity-btn');

		$incrBtn.on('click', (e) => {
			const $button  = $(e.currentTarget);
			const $input   = $button.parent().find('input[type=number]');
			const oldVal   = parseFloat($input.val());
			// const minVal   = parseFloat($input.attr('min'));
			const maxVal   = parseFloat($input.attr('max'));
			let newVal;

			// Increase
			if ($button.data('action') == 'increase') {
				newVal = oldVal + 1;
				if (oldVal === 1) {
					$button.parent().find('.js-quantity-btn[data-action="decrease"]').prop('disabled', false);
				}
				if (maxVal && newVal === maxVal) {
					$button.prop('disabled', true);
				}
			// Decrease
			} else {
				if (oldVal > 1) {
					newVal = oldVal - 1;
					if (maxVal && oldVal === maxVal) {
						$button.parent().find('.js-quantity-btn[data-action="increase"]').prop('disabled', false);
					}
				} else {
					newVal = 1;
					$button.prop('disabled', true);
				}
			}

			$input.val(newVal);
			$input.change();
			e.preventDefault();
		});
	}

	return {
		init,
	};
})();