export default (function() {
	let video  = null;
	let source = null;
	let play   = null;
	let pause  = null;

	function init() {
		video  = $('.js-video');

		if (video.length > 0) {
			source = $(video).find('.js-video-source');
			play   = $(video).find('.js-video-play');
			pause  = $(video).find('.js-video-pause');

			// Controls
			$(play).on('click', (e) => {
				e.preventDefault();
				$(source).get(0).play();
			});

			$(pause).on('click', (e) => {
				e.preventDefault();
				$(source).get(0).pause();
			});

			// Events
			$(source).on('play', () => {
				$(video).addClass('is-playing');
			});

			$(source).on('pause', () => {
				$(video).removeClass('is-playing');
			});
		}
	}

	return {
		init
	};
})();