import { getCookie, setCookie } from 'utils/Cookies';


export default (function() {
	const modal = $('#brochure-request');

	function init() {
		const hasRequested = getCookie('brochure_requested');

		if (!hasRequested) {
			openModal(modal);
		}

		// Close button
		$(modal).find('.js-close-modal').on('click tap', e => {
			e.preventDefault();

			// Check if previous page was on same domain
			if (document.referrer.split('/')[2] != window.location.host){
				window.location.href = window.theme.home_url;
			} else {
				window.history.back();
			}
		});
	}

	function success(form) {
		console.log(form);

		setCookie('brochure_requested', 1, 365);
		setTimeout(() => {
			closeModal(modal)
		}, 2000);
	}

	function openModal(modal) {
		$(modal).modal({
			show    : true,
			backdrop: 'static',
			keyboard: false
		});
	}

	function closeModal(modal) {
		$(modal).modal('hide');
	}

	return {
		init,
		success
	};
})();