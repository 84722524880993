/* global PACKAGE */

export default {
	APP: {
		NAME                  : PACKAGE.NAME,
		DESCRIPTION           : PACKAGE.DESCRIPTION,
		VERSION               : PACKAGE.VERSION,
		AUTHOR                : PACKAGE.AUTHOR,
		CREATION_DATE         : PACKAGE.CREATION_DATE,
		BUILD_DATE            : PACKAGE.BUILD_DATE,
		FACEBOOK_APP_ID       : ''
	},
	DEBUG: {
		SEND_ANALYTICS        : true && PACKAGE.PRODUCTION,
		IS_PRODUCTION         : PACKAGE.PRODUCTION
	},
	UI: {
		SOUND_FADE_TIME       : 0.35,
		SCROLL_DURATION       : 0.5,
		BREAKPOINT_ORDER      : ['xs', 'sm', 'md', 'lg', 'xl']
	},
	TIMING: {
		CAROUSEL_LOADING_TIME : 5000,
		LOADING_SPINNER_TIME  : 2500,
		LOADING_FADE_IN       : 1000
	},
	EASINGS: {
		EASE_IN               : '0.4,0,0.68,0.06',
		EASE_IN_TO_LINEAR     : '0.5,0,0.5,0.5',
		EASE_IN_OUT           : '0.66,0,0.34,1',
		EASE_IN_OUT_VRRROOM   : '0.35, 0.1, 0.65, 0.95',
		EASE_OUT              : '0.4,1,0.68,1',
		EASE_OUT_LONG         : '0,0.85,0.25,1'
	},
	COPYRIGHT:
`                                     
      This piece of technology       
  has been lovingly handcrafted by   
               Clarify               
         https://clarify.nl          
                                     `
};


export const EASING = {
	IN         : '0.4,0,0.68,0.06',
	IN_OUT     : '0.66,0,0.34,1',
	OUT        : '0.4,1,0.68,1',
	OUT_LONG   : '0,0.85,0.25,1',
	IN_OUT_LONG: '0.35,0.35,0,1'
};