import 'slick-carousel';

export default (function() {
	let _$carousel = null;

	function init() {
		const $carousel    = $('.js-press-carousel');
		_$carousel = $carousel;

		if ($carousel.length > 0) {
			$carousel.each((index, carousel) => {
				let _$nav = $(carousel).siblings('.js-carousel-nav');
				$(carousel).slick({
					cssEase       : 'cubic-bezier(0.15, 0, 0, 1)',
					infinite      : false,
					speed         : 500,
					fade          : false,
					dots          : false,
					adaptiveHeight: false,
					variableWidth : true,
					appendArrows: _$nav,
					prevArrow   : '<a class="slick-prev slick-arrow" href="#"><i class="icon icon-chevron-left" aria-hidden="true"></i></a>',
					nextArrow   : '<a class="slick-next slick-arrow" href="#"><i class="icon icon-chevron-right" aria-hidden="true"></i></a>',
				});
			});
		}

		// onResize();
		$(window).on('resize', () => {
			onResize();
		});
	}

	function onResize() {
		if (!_$carousel) {
			return;
		}

		_$carousel.each(function(){
			$(this).css({
				'width' : '',
				'height': ''
			});
		});

		_$carousel.each(function() {
			$(this).css({
				'width' : $(this).parent().width()
			});
		});
	}

	return {
		init,
	};
})();
