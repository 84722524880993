export default (function() {

	function init(forms, labels) {
		// One ninja form load
		if (forms.hasClass('nf-form-cont')) {
			$(document).on('nfFormReady', () => {
				floatLabels(forms, labels);
			});
		} else {
			floatLabels(forms, labels);
		}

		// Style checkboxes on MailPlus Forms
		if (forms.hasClass('mpForm')) {
			styleMailPlusForm(forms, labels);
		}
	}

	function floatLabels(forms, labels = 'div') {
		$.each(forms, (i, form) => {

			// Hide Label
			$(form).find('input:not([type=hidden]):not([type=radio]):not([type=checkbox]):not([type=button]):not([type=submit]), textarea').each((i, field) => {
				$(field).closest(labels).addClass('hide-label');
				if ($(field).val() !== '') {
					$(field).closest(labels).addClass('show-label');
				}
			});

			// Add placeholder text
			$(form).find('input:not([type=hidden]):not([type=radio]):not([type=checkbox]):not([type=button]):not([type=submit]), textarea').each((i, field) => {
				let fieldId;
				let label;

				// Get label
				if ($(field).hasClass('datepicker')) {
					fieldId = $(field).siblings('input[type=hidden]').attr('id');
					label   = $(form).find('label[for="' + fieldId + '"]').text();
				} else if ($(field).hasClass('mpDD')) {
					label   = 'DD';
				} else if ($(field).hasClass('mpMM')) {
					label   = 'MM';
				} else if ($(field).hasClass('mpYYYY')) {
					label   = 'YYYY';
				} else {
					fieldId = $(field).attr('id');
					label   = $(form).find('label[for="' + fieldId + '"]').text();
				}

				if (!$(field).attr('placeholder')) {
					$(field).attr('placeholder', label);
				}
			});

			// Float label on focus
			$(form).find('input:not([type=hidden]):not([type=radio]):not([type=checkbox]):not([type=button]):not([type=submit]), textarea').on('keyup blur focus', (e) => {
				const parent = $(e.currentTarget).closest(labels);

				if (e.type == 'keyup') {
					if ($(e.currentTarget).val() == '') {
						parent.removeClass('show-label');
					} else {
						parent.addClass('show-label');
					}
				} else if (e.type == 'blur') {
					if ($(e.currentTarget).val() == '') {
						parent.removeClass('show-label');
					} else {
						parent.addClass('show-label unhighlight-label');
					}
				} else if (e.type == 'focus') {
					if ($(e.currentTarget).val() !== '') {
						parent.removeClass('unhighlight-label');
					}
				}
			});
		});
	}

	function styleMailPlusForm(forms, labels = 'div') {
		$.each(forms, (i, form) => {
			// Add checkbox class
			$(form).find(labels).each((i, label) => {
				$(label).find('input[type=checkbox]').each((i, field) => {
					$(field).closest('li').addClass('c-checkbox');
				});
			});

			// Add button class
			$(form).find(labels).each((i, label) => {
				$(label).find('input[type=submit]').each((i, field) => {
					$(field).closest('div').addClass('c-button--arrow');
				});
			});
		});
	}

	return {
		init
	};
})();