import 'floatthead';
import common from 'routes/common';
import { getCookie } from 'utils/Cookies';
import ScrollOverlay from 'modules/scrollOverlay';

export default (function() {
	let comparer     = null;
	let noResult     = null;
	let result       = null;
	let sheet        = null;
	let table        = null;
	let scroll       = null;
	let scrollOffset = 0;

	function init() {
		comparer = $('.js-comparer');
		noResult = $('.js-comparer-no-result');
		result   = $('.js-comparer-result');
		$(window).resize(onResize);

		getCompareCookie();
	}

	// Get Compare Cookie
	function getCompareCookie() {
		const productIds = getCookie('bike_comparer');

		if (productIds) {
			getBikes(JSON.parse(productIds));
		} else {
			noResult.removeClass('is-hidden');
		}
	}

	// Get products and show them
	function getBikes(ids) {
		$.ajax({
			url : window.theme.ajax_url,
			type: 'post',
			data: {
				action  : 'get_comparer_result',
				security: window.theme.ajax_nonce,
				ids     : ids
			},
			dataType: 'html',
			beforeSend: () => {
				comparer.addClass('is-loading');
				if (sheet) sheet.remove();
				result.addClass('is-loading');
			},
			success: (bikes) => {
				comparer.removeClass('is-loading');
				result.append(bikes);
				result.removeClass('is-loading');

				common.init();

				sheet = $('.js-comparer-sheet');
				table = sheet.find('table');
				ScrollOverlay.init($('.js-comparer').children().first());
				initScroll();
			},
			error: (jqXHR, error) => {
				console.log(jqXHR.responseText, error);
				comparer.removeClass('is-loading');
				result.removeClass('is-loading');
			}
		});
	}

	function initScroll() {
		if ($('.js-comparer-fixed-info').length > 0) {
			const prev = $('.js-comparer-fixed-info').prev();
			scrollOffset = $(prev).offset().top + $(prev).outerHeight(true);
		}

		if (!scroll) {
			scroll = $(window).scroll(onScroll);
		}
	}

	function onResize(e) {
		if ($('.js-comparer-fixed-info').length > 0) {
			const prev = $('.js-comparer-fixed-info').prev();
			scrollOffset = $(prev).offset().top + $(prev).outerHeight(true);
		}
	}

	function onScroll(e) {
		let scrollPos = {
			top: $(window).scrollTop(),
			left: $(window).scrollLeft()
		}
		if (scrollPos.top >= scrollOffset) {
			$('body').addClass('has-sticky-header');
		} else {
			$('body').removeClass('has-sticky-header');
		}
	}

	return {
		init
	};
})();