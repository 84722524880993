export default (function() {
	let _el;
	let _openBtn;
	let _closeBtn;
	let _applyBtn;
	let _footer;
	let _modal;
	let _onApply;
	let _counter;

	function init(el) {
		_el = el;
		_openBtn = el.find('.js-store-filter-open');
		_closeBtn = el.find('.js-store-filter-close');
		_applyBtn = el.find('.js-store-filter-apply');
		_modal = el.find('.js-store-filter-modal');

		_openBtn.click(e => {
			e.preventDefault();
			openModal();
		});

		_closeBtn.click(e => {
			e.preventDefault();
			closeModal();
		});

		_applyBtn.click(e => {
			e.preventDefault();

			if (_onApply) {
				_onApply();
			}

			closeModal();
		});
	}

	function closeModal() {
		_modal.fadeOut();
	}

	function openModal() {
		_modal.css('display', 'flex')
			.hide()
			.fadeIn();
	}

	function setNumber(number) {
		if (!_counter) {
			_counter = _applyBtn.find('[data-counter]');
		}
		_counter.text(` (${number})`);
	}

	function onApply(funk) {
		if (funk) {
			_onApply = funk;
		}
	}

	function start(number) {
		setNumber(number);

		if (_el) {
			$(_el).addClass('is-shown');
		}
	}

	return {init, setNumber, onApply, start};
})();