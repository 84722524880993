export default (function() {
	let lazyload;
	let lazyloaded;

	function init() {
		lazyload    = $('img.lazyload, img.lazyloading');
		lazyloaded  = $('img.lazyloaded');
		onLazyLoad();
	}

	function onLazyLoad() {
		// Lazyloaded
		lazyloaded.closest('picture').addClass('lazyloaded');

		// Lazyload
		lazyload.one('load', e => {
			$(e.currentTarget).closest('picture').addClass('lazyloaded');
		}).each((index, element) => {
			if (element.complete) {
				$(element).on('load');
			}
		});
	}

	return {
		init,
	};
})();