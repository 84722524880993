export default (function() {
	let docHeight;
	let winHeight;

	function init() {
		onResize();
		onScroll();

		$(window).on('resize touchend', onResize);
		$(window).scroll(onScroll)
	}

	function onResize() {
		docHeight = document.documentElement.scrollHeight;
		winHeight = window.innerHeight;
	}

	function onScroll() {
		const scrollTop = $(window).scrollTop();

		if (scrollTop >= winHeight / 2 ||
			docHeight - winHeight < winHeight / 2) {
			$('body').addClass('has-scrolled');
		} else {
			$('body').removeClass('has-scrolled');
		}
	}

	return {
		init
	};
})();