import Modal from 'modules/Modal';


export default (function() {

	function init(link, modal) {
		Modal.init(link, modal, preselectBike);
	}

	// Pre-select bike in form when value exists
	function preselectBike(link, modal) {
		const bikeTitle  = $(link).data('title');
		const selectBike = $(modal).find('form.mpForm select[id=field1367]');
		const option     = $(selectBike).find(`option[value="${bikeTitle}"]`);

		if (option.length > 0) {
			$(selectBike).val(bikeTitle).trigger('change');
			$(option).attr('selected', true);
		}
	}

	return {
		init
	};
})();