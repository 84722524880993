export default (function() {
	function init() {
		const _$collapse = $('.js-collapse');
		if (_$collapse.length > 0) {
			_$collapse.each(initCollapse);
		}
	}

	function initCollapse(index, collapse) {
		const _$collapse = $(collapse);
		const _$toggle = _$collapse.find('.js-collapse-toggle');
		_$toggle.unbind('click');
		_$toggle.click((e) => {
			e.preventDefault();
			handleClick(_$toggle, _$collapse);
		});
	}

	function handleClick(_$toggle, _$collapse) {
		const _readMore = _$toggle.html();

		_$collapse.toggleClass('is-collapse-open');
		_$toggle.html(_$toggle.data('content'));
		_$toggle.data('content', _readMore);
		_$collapse.trigger('collapse:toggle');
	}

	return {
		init
	};
})();