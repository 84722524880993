import BreakpointSniffer from 'utils/BreakpointSniffer'

export default (function() {
	let _$menu;
	let _$close;
	let _$open;
	let _$title;
	let _$bikes;
	let _$activeBike;

	function init(menu) {
		_$menu       = menu;
		_$close      = _$menu.find('.js-model-menu-close');
		_$title      = $('.js-model-title');
		_$open       = $('.js-model-menu-open');
		_$activeBike = $('.js-carousel-bike').first().addClass('is-active-bike')
		_$title      = $('.js-model-title');
		_$bikes      = $('.js-model-nav-item');

		// pas title aan
		// verander active class

		_$bikes.on('click', e => {
			$('.js-carousel-bike.is-active-bike').removeClass('is-active-bike');
			_$title.html($(e.currentTarget).find('.b-bike-nav-mobile__title').text());

			$('.js-carousel-bike').eq($(e.currentTarget).index() - 1).addClass('is-active-bike');
			_$menu.closest('.js-category-carousel').removeClass('model-menu-open');
		})

		_$title.html(_$activeBike.find('.js-bike-title').text());

		_$open.on('click', () => {
			_$menu.closest('.js-category-carousel').addClass('model-menu-open');
		})

		_$close.on('click', () => {
			_$menu.closest('.js-category-carousel').removeClass('model-menu-open');
		})

		if (!BreakpointSniffer.isBigger('lg') && !_$menu.closest('.js-category-carousel').hasClass('is-mobile-active')) {
			addMobileMenu();
		}

		$(window).on('resize', onResize);
	}

	function addMobileMenu() {
		_$menu.closest('.js-category-carousel').hide().addClass('is-mobile-active').show();
	}

	function removeMobileMenu() {
		_$menu.closest('.js-category-carousel').hide().removeClass('is-mobile-active').show();
	}

	function onResize() {
		if (!BreakpointSniffer.isBigger('lg') && !_$menu.closest('.js-category-carousel').hasClass('is-mobile-active')) {
			addMobileMenu();
		}
		else if (BreakpointSniffer.isBigger('lg') && _$menu.closest('.js-category-carousel').hasClass('is-mobile-active')) {
			removeMobileMenu();
		}
	}

	return {
		init
	};
})();