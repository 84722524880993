import 'slick-carousel';

export default (function() {
	function init(carousel) {
		if ($(carousel).length > 0) {
			$(carousel).each((i, el) => {
				initCarousel(el);
			});
		}
	}

	function initCarousel(el) {
		let _$nav = $(el).siblings('.js-carousel-nav');

		$(el).slick({
			cssEase       : 'cubic-bezier(0.15, 0, 0, 1)',
			infinite      : false,
			speed         : 500,
			fade          : false,
			dots          : true,
			adaptiveHeight: false,
			variableWidth : false,
			slidesToShow  : 1,
			appendArrows: _$nav,
			prevArrow   : '<a class="slick-prev slick-arrow" href="#"><i class="icon icon-chevron-left" aria-hidden="true"></i></a>',
			nextArrow   : '<a class="slick-next slick-arrow" href="#"><i class="icon icon-chevron-right" aria-hidden="true"></i></a>',
			autoplay      : true,
			autoplaySpeed : 5000
		});
	}

	return { init };
})();