export default (function() {
	function init (link, title) {
		if (link.length > 0) {
			const content = $('.js-copy-content');
			const contentHtml = $(content).html();
			const titleHtml = $(title).parent().html();
			const copyWrapper = $('<article></article>');
			$(copyWrapper).append(titleHtml);
			$(copyWrapper).append(contentHtml);

			$(link).click(e => {
				e.preventDefault();
				setClipboard($(copyWrapper).html());
				openTooltip(link);
			});
		}
	}

	function setClipboard(text) {
		const type = "text/html";
		const blob = new Blob([text], { type });
		const data = [new ClipboardItem({ [type]: blob })];
		navigator.clipboard.write(data);
	}

	function openTooltip(el) {
		$(el).tooltip('show');
		window.setTimeout(() => $(el).tooltip('hide'), 2000);
	}

	return {init};
})();