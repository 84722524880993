export default (function() {

	function init() {
		if ('ontouchstart' in window.document.documentElement || window.navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0) {
			$('body').removeClass('no-touch');
		}
	}

	return {
		init
	};
})();