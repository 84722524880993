import RenderLoop from 'tween/RenderLoop';

export default (function() {
	let _$scrollable = null;
	let scrollPos = {};

	function init(scrollable) {
		_$scrollable = scrollable;

		if (_$scrollable.length > 0) {
			$(window).scroll(onScroll);
			$(window).resize(onScroll);
			onScroll();

			RenderLoop.add(_$scrollable, () => {
				update();
			});
		}
	}

	function onScroll() {
		scrollPos = {
			left: $(window).scrollLeft(),
			right: _$scrollable.width() - $(window).width() - $(window).scrollLeft()
		}
		update();
	}

	function update() {
		$('.js-scroll-overlay.is-left').css('opacity', (scrollPos.left* .01) ** 2);
		$('.js-scroll-overlay.is-right').css('opacity', (scrollPos.right* .01) ** 2);
	}

	return {init};
})();