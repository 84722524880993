export default (funk, wait = 0.1, immediate = false) => {
	let timer = null;

	return (...args) => {
		let resolve;

		const promise = new Promise(_resolve => {
			resolve = _resolve;
		}).then(() => {
			return funk(...args);
		});

		if (immediate && !timer) {
			resolve();
		}

		if (timer) {
			window.clearTimeout(timer);
		}

		timer = window.setTimeout(() => {
			timer = null;

			if (!immediate) {
				resolve();
			}
		}, wait * 1000);

		return promise;
	};
};