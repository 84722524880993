export default (function() {
	let _el;
	let _template;
	let _close;

	function init(el, templateEl) {
		_el = el;

		const templateString = $(templateEl).text();
		_template = window._.template(templateString);
		_close = $(_el).find('.js-close-modal');

		_close.on('click', e => {
			e.preventDefault();

			$(_el).modal('hide');
		});
	}

	function open(dealer) {
		const wrapper = $(_el).find('[data-type="content"]');
		const template = createTemplate(dealer);

		wrapper.html(template);
		$(_el).modal('show');
	}

	function createTemplate(dealer) {
		const directions = `${dealer.Dealer} ${dealer.Straat} ${dealer.Plaats}`;
		dealer.Directions = `http://maps.google.com/maps?q=${encodeURIComponent(directions)}`;
		return _template(dealer);
	}

	return {
		init,
		open
	}
})()