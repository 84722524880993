export default (function() {
	function init() {
		if (window.Marionette && window.Backbone) {
			const submitController = window.Marionette.Object.extend( {
				initialize: function() {
					this.listenTo( window.Backbone.Radio.channel( 'forms' ), 'submit:response', this.actionSubmit );
				},
				actionSubmit: function( response ) {
					if (response?.data?.actions?.webhooks) {
						const {
							_errorURL: errorURL,
							_successURL: successURL
						} = response.data.actions.webhooks?.args;

						const htmlResponse = response?.data?.actions?.webhooks?.response?.body;
						const regex = new RegExp('!doctype html');
						const isHtml = regex.test(htmlResponse);

						if (isHtml && errorURL && successURL) {
							const urlMeta = /<meta name="current_url" content="(.*?)"/;
							const match = htmlResponse.match(urlMeta);

							if (match) {
								const url = match[1];
								window.location.href = url;
							} else {
								console.log('cant find url in response', htmlResponse);
							}
						}
					}
				},
			});

			new submitController();
		} else {
			if (!window.Marionette) {
				console.log('Marionette not found');
			}
			if (!window.Backbone) {
				console.log('Backbone not found')
			}
		}
	}

	return { init };
})();