import ProductTestDrive from 'modules/productTestDrive';
import { setCookie } from 'utils/Cookies';


export default (function() {
	let main          = null;
	let start         = null;
	let tabs          = null;
	let prevNext      = null;
	let steps         = null;
	let routes        = null;
	let option        = null;
	let advice        = null;
	let compare       = null;
	let reset         = null;
	let retry         = null;
	let results       = null;
	let result        = null;
	let answers       = {};
	let path          = [];
	let _chosenRoute  = null;
	let _currentRoute = 0;
	let _currentIndex = 0;
	let originalTitle = null;
	let xhr           = null;
	let resultLink    = null;

	function init() {
		main          = $('.js-wizard');
		start         = $('.js-wizard-start');
		prevNext      = $('.js-wizard-prev, .js-wizard-next');
		steps         = $('.js-wizard-step');
		routes        = $('.js-wizard-route');
		option        = $('.js-wizard-option');
		advice        = $('.js-wizard-advice');
		reset         = $('.js-wizard-reset');
		resultLink    = '.js-wizard-link-results';

		// Check for exisiting step slug
		const urlRoute   = main.data('route-url');
		const urlStep    = main.data('step-url');
		const urlResult1 = main.data('result1-url');
		const urlResult2 = main.data('result2-url');
		const urlResult3 = main.data('result3-url');
		const curUrl     = window.location.href;

		// Remove each url part from url
		const newUrl = [urlRoute, urlStep, urlResult1, urlResult2, urlResult3].reverse().reduce((string, el) => {
			return string.replace(new RegExp(`${el}/?$`, 'i'), '');
		}, curUrl);

		history.replaceState(null, null, newUrl);

		// Click on start
		start.on('click', (e) => {
			e.preventDefault();
			nextStep();
		});

		// Click on wizard mobile arrow nav
		prevNext.on('click', (e) => {
			e.preventDefault();

			if (!$(e.currentTarget).is('.disabled')){
				main.removeClass('has-results');
				deActivateAll();

				_currentIndex = $(e.currentTarget).data('index');
				setWizardSlug();
				activateCurrent();
			}
		});

		// Click on wizard route
		routes.on('click', (e) => {
			e.preventDefault();
			_currentRoute = $(e.currentTarget).data('route');
			_chosenRoute  = $(e.currentTarget).data('route-label');

			setRoute(_currentRoute);
			saveAnswer(_currentIndex, $(e.currentTarget));
		});

		// Click on wizard option
		option.on('click', (e) => {
			e.preventDefault();
			saveAnswer(_currentIndex, $(e.currentTarget));
		});

		// Click on reset
		reset.on('click', (e) => {
			e.preventDefault();
			resetWizard();
		});

		// Set original title
		originalTitle = document.title;
		window.addEventListener('popstate', (e) => {
			popStateListener(e);
		});
	}

	function deActivateAll() {
		steps.removeClass('active');
	}

	function activateCurrent() {
		steps.not('.hidden').eq(_currentIndex).removeClass('disabled').addClass('active');

		// Activate prev/next links
		steps.not('.hidden').eq(_currentIndex - 1).find('.js-wizard-next').removeClass('disabled');
		steps.not('.hidden').eq(_currentIndex).find('.js-wizard-prev').removeClass('disabled');
	}

	// Set Wizard Route (hide all other steps)
	function setRoute(route, changeAdvice = true) {
		const allRoutes = $("[class*='js-route-opt-']");
		const curRoute  = $(`.js-route-opt-${route}`);

		if (changeAdvice) {
			// Change advice url
			const resultUrl    = steps.last().data('url');
			const newResultUrl = resultUrl.replace('%%slug%%', _chosenRoute);
			steps.last().data('url', newResultUrl);
		}

		allRoutes.addClass('hidden');
		curRoute.removeClass('hidden');
	}

	// Reset Wizard
	function resetWizard() {
		main.removeClass('has-results');
		routes.removeClass('is-chosen');
		option.removeClass('is-chosen');

		deActivateAll();
		if (xhr) {
			xhr.abort();
		}

		answers       = {};
		path          = [];
		_currentIndex = 0;

		// Re-add hidden classes
		$("[class*='js-route-opt-']").addClass('hidden');
		// setWizardSlug();
		activateCurrent();
		document.title = originalTitle;

		// Re-add disabled classes
		$(`.js-wizard-tab:gt(${_currentIndex + 1})`).find('a').addClass('disabled');
		prevNext.addClass('disabled');
	}

	// Validate current step
	function saveAnswer(index, link) {
		const curStep = steps.not('.hidden').eq(index);
		const feature = curStep.data('product-feature');
		const answer  = link.data('feature-value');
		const title   = $(curStep).data('title');
		const choice  = link.data('label');

		// Highlight answer
		$(curStep).find('.js-wizard-route, .js-wizard-option').removeClass('is-chosen');
		link.addClass('is-chosen');

		if (answer) {
			answers[feature] = JSON.parse(answer);
		}
		path[index - 1] = {
			title,
			choice
		};
		nextStep();
	}

	// Go to next step
	function nextStep() {
		deActivateAll();
		_currentIndex++;
		activateCurrent();
		setWizardSlug();

		if (_currentIndex === (steps.not('.hidden').length - 1)) {
			getResults();
		}
	}

	// Change the wizard url
	function setWizardSlug() {
		const stepid = steps.not('.hidden').eq(_currentIndex).attr('id');
		const title  = steps.not('.hidden').eq(_currentIndex).data('title');
		const url    = steps.not('.hidden').eq(_currentIndex).data('url');
		history.pushState({stepid, title, index: _currentIndex}, null, url);
		document.title = `${originalTitle} - ${title}`;
	}

	// Listen to back/forward
	function popStateListener(e) {
		if (!e.state) {
			resetWizard();
		} else {
			const stepIndex = e.state.index;
			const stepTitle = e.state.title;
			_currentIndex = stepIndex;

			deActivateAll();
			setRoute(_currentRoute, false);
			if (xhr) {
				xhr.abort();
			}
			activateCurrent();

			if (_currentIndex === (steps.not('.hidden').length - 1)) {
				getResults();
			} else {
				main.removeClass('has-results');
			}

			document.title = `${originalTitle} - ${stepTitle}`;
		}
	}

	// Get results and show them
	function getResults() {
		const pageId = advice.data('page-id');

		xhr = $.ajax({
			url : window.theme.ajax_url,
			type: 'post',
			data: {
				action  : 'get_wizard_advice',
				security: window.theme.ajax_nonce,
				criteria: answers,
				pageId,
				path
			},
			dataType: 'html',
			beforeSend: () => {
				if (results) results.remove();
				advice.addClass('is-loading');
			},
			success: (data) => {
				main.addClass('has-results');
				advice.append(data);
				advice.removeClass('is-loading');

				// Set result selectors
				compare = $('.js-wizard-compare');
				retry   = $('.js-wizard-reset');
				results = $('.js-wizard-results');
				result  = $('.js-wizard-result');
				const resultPrev = $('.js-wizard-prev-results');

				// Get url params
				const stepid  = steps.not('.hidden').eq(_currentIndex).attr('id');
				const title   = steps.not('.hidden').eq(_currentIndex).data('title');
				let resultUrl = steps.not('.hidden').eq(_currentIndex).data('url');

				// Collect product id's
				const productIds = [];
				$(result).each((i, res) => {
					const prodId   = $(res).data('product-id');
					const prodSlug = $(res).data('product-slug');

					if (prodId) {
						productIds.push(prodId);
					}

					if (prodSlug) {
						resultUrl += `${prodSlug}/`;
					}
				});

				// Change url
				history.pushState({stepid, title, index: _currentIndex}, null, resultUrl);

				// Set listeners
				compare.on('click', () => {
					setCompareCookie(productIds);
				});

				resultPrev.on('click', e => {
					e.preventDefault();
					deActivateAll();
					_currentIndex--;
					activateCurrent();
					setWizardSlug();
				})

				retry.on('click', (e) => {
					e.preventDefault();
					resetWizard();
				});

				initResultLinks();
				$(advice).find('.variations_form').each(function () {
					$(this).wc_variation_form();
				});
				// Init Sidepanel
				ProductTestDrive.init($('.js-request-test-drive'), $('#test-drive'));
			},
			error: (jqXHR, error) => {
				console.log(jqXHR.responseText, error);
				advice.removeClass('is-loading');
			}
		});
	}

	function initResultLinks() {
		$(resultLink).on('click', (e) => {
			e.preventDefault();

			if (!$(e.currentTarget).is('.disabled')){
				main.removeClass('has-results');
				deActivateAll();

				_currentIndex = $(e.currentTarget).data('index');
				setWizardSlug();
				activateCurrent();
			}
		});
	}

	// Set Compare Cookie
	function setCompareCookie(ids) {
		setCookie('bike_comparer', JSON.stringify(ids), 100);
	}

	return {
		init,
	};
})();