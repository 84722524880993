/* global $ */
import CONFIG from '../core/Config';

export default (function() {
	let _breakpoint = null;

	function start() {
		$(window).on('resize', onResize);
		onResize();
	}

	function isSmaller(breakpoint) {
		return CONFIG.UI.BREAKPOINT_ORDER.indexOf(_breakpoint) <= CONFIG.UI.BREAKPOINT_ORDER.indexOf(breakpoint);
	}

	function isBigger(breakpoint) {
		return CONFIG.UI.BREAKPOINT_ORDER.indexOf(_breakpoint) >= CONFIG.UI.BREAKPOINT_ORDER.indexOf(breakpoint);
	}

	function onResize() {
		if (document.querySelector('.js-breakpoint-sniffer') !== null) {
			const _result = window.getComputedStyle(document.querySelector('.js-breakpoint-sniffer'), ':before');

			if (null !== _result) {
				_breakpoint = _result.getPropertyValue('content').replace(/"/g, '');
			}
		}
	}

	$(document).ready(start);

	return {
		isSmaller,
		isBigger
	};
})();