import CONFIG from '../core/Config';

export default (function() {
	function init() {
		const _$loadIndicator = $('.js-loader');
		const _loadRadius     = _$loadIndicator.attr('r');
		const _loadOffset     = Math.round(2 * Math.PI * _loadRadius);
		// Set array with offset
		if (_$loadIndicator.length > 0) {
			_$loadIndicator.css('stroke-dasharray', _loadOffset);
			// Set the interval to loop
			setInterval(() => {
				_$loadIndicator.animate({'stroke-dashoffset': -_loadOffset}, CONFIG.TIMING.LOADING_SPINNER_TIME, 'easeInOutCubic', () => {
					_$loadIndicator.css('stroke-dashoffset', _loadOffset);
				});
			}, CONFIG.TIMING.LOADING_SPINNER_TIME);
		}
	}

	return {
		init
	};
})();