export default (function() {
	let clickedLink = null;
	let _handler = 'click';

	function init(link, modal, callbackOpen = null, callbackClose = null) {
		if (link.is('input')) {
			_handler = 'change';
		}

		link.on(_handler, (e) => {
			e.preventDefault();
			clickedLink = e.currentTarget;
			openModal(modal);
		});

		$(modal).find('.js-close-modal').on('click tap', e => {
			e.preventDefault();
			closeModal(modal);
		});

		$(modal).on('shown.bs.modal', () => {
			if (callbackOpen) {
				callbackOpen(clickedLink, modal);
			}
		});

		$(modal).on('hidden.bs.modal', () => {
			if (callbackClose) {
				callbackClose(clickedLink, modal);
			}
		});
	}

	function openModal(modal) {
		$(modal).modal('show');
	}

	function closeModal(modal) {
		$(modal).modal('hide');
	}

	return {
		init,
		openModal,
		closeModal
	};
})();