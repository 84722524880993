import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import cloneDeep from 'lodash/cloneDeep';
import distance from '@turf/distance';

import Modal from 'modules/Modal';
import storeDetails from 'modules/storeDetails';
import storeFilters from 'modules/storeFilters';
import storeList from 'modules/storeList';

export default (function() {
	let dealersSource;
	let placeholderText;
	let filterInputs;
	let map;
	let Geocoder;
	let _listItems;
	let _dealers = [];
	let currentLocation;
	let tempDealers;

	const originalFilters = new Map();
	const layerIDs = [];


	function init(searchEl, filtersEl, mapEl) {
		placeholderText      = searchEl.find('.js-search-placeholder').text();
		filterInputs         = filtersEl.find('.js-store-locator-filter');
		mapboxgl.accessToken = window.theme.mapbox_at;

		storeFilters.init($('.js-store-filter'));

		storeFilters.onApply(applyFilter);

		storeDetails.init($('#store-modal'), $('#store-modal-template'));
		storeList.init($('.js-store-list'), $('#store-item-template'));

		const dealerTypes    = window.theme.dealer_types;

		initMapBox(searchEl[0], mapEl[0], () => {
			loadDealers(mapEl[0], dealerTypes);

			// Don't ask for current user's location yet
			// getUserLocation();
		});

		Modal.init($('.js-open-legend'), $('#map-legend'));
	}

	// function getUserLocation() {
	// 	navigator.geolocation.getCurrentPosition(setUserLocation);
	// }

	// function setUserLocation(position = {}) {
	// 	const {
	// 		coords: {
	// 			latitude = null,
	// 			longitude = null
	// 		} = {}
	// 	} = position;

	// 	map.flyTo({
	// 		center : [longitude, latitude],
	// 		zoom   : 12
	// 	});
	// }

	// Load Dealers from SalesForce API
	function loadDealers(mapEl, dealerTypes) {
		$.ajax({
			url: window.theme.ajax_url,
			type: 'POST',
			data: {
				action: 'get_dealers'
			},
			dataType: 'json',
			beforeSend: () => {
				$(mapEl).addClass('is-loading');
			},
			success: response => {
				if (response?.success) {
					dealersSource = response?.data;
					addDealers(dealerTypes, dealersSource);
					storeFilters.start(dealersSource.features.length);
				} else {
					const error = response?.data?.[0];
					alert(error?.message);
				}
			},
			error: (jqXHR, error) => {
				console.log(error);
			},
			complete: () => {
				$(mapEl).removeClass('is-loading');
			}
		});
	}

	function initMapBox(searchEl, mapEl, callback) {
		map = new mapboxgl.Map({
			container: mapEl,
			style    : 'mapbox://styles/mapbox/light-v9',
			minZoom  : 2,
			maxZoom  : 16,
			center   : [5.115485, 51.579226],
			zoom     : 6
		});

		// Force resize
		map.on('load', () => {
			map.resize();
			callback();
		});

		// Add Geolocate control to the map.
		map.addControl(new mapboxgl.GeolocateControl({
			positionOptions: {
				enableHighAccuracy: true
			},
			trackUserLocation: true
		}));

		// Add Geocoder for searching
		Geocoder = new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			flyTo      : false,
			marker     : false,
			countries  : 'nl,be,de',
			types      : 'country,region,postcode,district,place,locality,neighborhood,poi',
			language   : 'nl,be,de',
			placeholder: placeholderText
		});
		searchEl.appendChild(Geocoder.onAdd(map));
		$(searchEl).find('.mapboxgl-ctrl input[type=text]').attr('autocomplete', 'off');

		// FlyTo Geocoder result
		Geocoder
			.on('loading', () => {
				$(searchEl).find('.mapboxgl-ctrl').addClass('is-loading');
			})
			.on('results', () => {
				$(searchEl).find('.mapboxgl-ctrl').removeClass('is-loading');
			})
			.on('result', (e) => {
				const result = e.result;

				map.flyTo({
					center : result.center,
					zoom   : 12
				});
			});

		$(window).on('map-resize', () => {
			map.resize();
		});
	}

	function addDealers(dealerTypes, dealers) {
		map.addSource('dealers', {
			'type'          : 'geojson',
			'data'          : dealers,
			'cluster'       : true,
			'clusterMaxZoom': 10,
			'clusterRadius' : 50
		});

		map.addSource('search-result', {
			'type': 'geojson',
			'data': {
				'type'    : 'FeatureCollection',
				'features': []
			}
		});

		map.addLayer({
			id    : 'clusters',
			type  : 'circle',
			source: 'dealers',
			filter: ['has', 'point_count'],
			paint : {
				'circle-color' : '#fa530b',
				'circle-radius': ['step', ['get', 'point_count'], 20, 25, 30, 50, 40]
			}
		});

		map.addLayer({
			id    : 'cluster-count',
			type  : 'symbol',
			source: 'dealers',
			filter: ['has', 'point_count'],
			layout: {
				'text-field': '{point_count_abbreviated}',
				'text-font' : ['Arial Unicode MS Bold'],
				'text-size' : 18
			},
			paint: {
				'text-color': '#fff'
			}
		});

		map.addLayer({
			'id'    : 'point',
			'source': 'search-result',
			'type'  : 'circle',
			'paint' : {
				'circle-radius': 10,
				'circle-color': '#000'
			}
		});

		// Get unique dealer types
		if (dealers.features) {
			_dealers = dealers.features;

			_dealers.forEach(dealer => {
				const shopType = dealerTypes.find(type => dealer.properties.Dealertype == type.name);
				dealer.properties.shopLabel = dealer.properties.Dealertype != 'Normal' ? shopType.label || dealer.properties.Dealertype : '';
				dealer.properties.shopTag = shopType.tagline;
			})

			const uniqueDealers = dealers.features ? dealers.features.reduce((arr, el) => {
				const type     = el.properties['Dealertype'];

				if (arr.includes(type)) {
					return arr;
				}
				arr.push(type);
				return arr;
			}, []) : null;

			// Add layer & image for each dealer type
			uniqueDealers.forEach((type) => {
				const typeSlug   = type.replace(/\s/g, '-').toLowerCase();
				const layerID    = `qwic-dealer-${typeSlug}`;
				const dealerType = dealerTypes.filter((obj) => {
					return obj.name === type;
				});

				// Get icon either from uploads or assets fallback
				let image;
				if (dealerType[0].icon) {
					image = dealerType[0].icon;
				} else {
					image = require(`images/${layerID}.png`);
				}

				layerIDs.push(layerID);
				map.loadImage(image, (error, icon) => {
					if (error) throw error;
					if (icon) {
						map.addImage(`${layerID}`, icon);
						if (!map.getLayer(layerID)) {
							let filter = [
								'all',
								['!', ['has', 'point_count']],
								['==', ['geometry-type'], 'Point'],
								['==', ['get', 'Dealertype'], type]
							];

							map.addLayer({
								id    : layerID,
								type  : 'symbol',
								source: 'dealers',
								layout: {
									'icon-image'           : `${layerID}`,
									'icon-allow-overlap'   : true,
									'icon-ignore-placement': true,
									'text-allow-overlap'   : true, // doesn't make sense I know
									'icon-size'            : 0.5,
									'icon-padding'         : 20 // offset for hovering to popup
								},
								filter
							});

							originalFilters.set(layerID, filter);

							map.on('click', layerID, (e) => {
								if (e.features[0]) {
									const selectedDealer = _dealers.find(dealer => dealer.properties.DealerId == e.features[0].properties.DealerId);
									storeDetails.open(selectedDealer.properties);
								}
							});
						}
					}
				});
			});
		}

		// Listen for the `result` event from the MapboxGeocoder
		Geocoder.on('result', (ev) => {
			map.getSource('search-result').setData(ev.result.geometry);
			currentLocation = ev.result.geometry;

			calculateDistance();
			sortDealers();
			refreshLists(_dealers);
		});

		// When the radio buttons change, update the source features
		filterInputs.on('change', (e) => {
			const value = $(e.currentTarget).val();
			storeList.filter(value);

			// Filter source & update it
			if (value !== 'all') {
				const cloneDealersSource = cloneDeep(dealersSource);
				const filteredData = cloneDealersSource.features.filter(feature => {
					return feature.properties['Dealertype'] == value;
				});

				cloneDealersSource.features = filteredData;
				tempDealers = cloneDealersSource;
			// Reset to original source
			} else {
				tempDealers = dealersSource;
			}

			storeFilters.setNumber(tempDealers.features.length);
		});
	}

	function applyFilter() {
		if (tempDealers) {
			map.getSource('dealers').setData(tempDealers);
			_dealers = tempDealers.features;

			calculateDistance();
			sortDealers();
			refreshLists(_dealers);
		}
	}

	function calculateDistance() {
		if (currentLocation) {
			_dealers.forEach(dealer => {
				dealer.properties.distance = distance(currentLocation, dealer.geometry);
			});
		}
	}

	function sortDealers() {
		_dealers = _dealers.sort((a, b) => {
			if (a.properties.distance > b.properties.distance) {
				return 1;
			}
			if (a.properties.distance < b.properties.distance) {
				return -1;
			}
			return 0;
		});
	}

	function refreshLists(dealers) {
		storeList.create(dealers);
		initListItems();
	}

	function openDetails(id) {
		const selectedDealer = _dealers.find(dealer => dealer.properties.DealerId == id);
		storeDetails.open(selectedDealer.properties);
	}

	function initListItems() {
		_listItems            = $('.js-store-item');

		$(_listItems).each((i, link) => {
			$(link).click(e => {
				e.preventDefault();

				const dealerId = $(link).data('id');

				openDetails(dealerId);
			});
		});
	}

	return {
		init,
	};
})();