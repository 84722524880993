import LockScroll from 'utils/LockScroll'
import BreakpointSniffer from 'utils/BreakpointSniffer'
import debounce from 'utils/debounce';

export default (function() {
	let _$header;
	let _$navToggle;
	let isOpen = false;

	function init(header) {
		_$header    =  header;
		_$navToggle = _$header.find('.js-nav-main-toggle');
		_$navToggle.on('click', toggleMenu);

		_$header.find('.js-menu-parent').on('click', function(e) {
			e.preventDefault();
			const section = $(this).find('.js-menu-child');
			$(section).addClass('is-active');
		});

		_$header.find('.js-menu-child a').on('click', e => {
			e.stopPropagation();
		});

		_$header.find('.js-main-menu, .js-menu-parent').hover(function() {
			if (BreakpointSniffer.isBigger('lg')) {
				$(this).parent().addClass('is-expanded');
			}
		}, function() {
			$(this).parent().removeClass('is-expanded');
		});

		_$header.find('.js-menu-back').on('click', resetSubs);

		$(window).resize(debounce(onResize));
	}

	function onResize() {
		if (isOpen && BreakpointSniffer.isBigger('lg')) {
			closeMenu();
		}
	}

	function resetSubs(e) {
		e.preventDefault();
		e.stopPropagation();
		_$header.find('.js-menu-child').removeClass('is-active');
	}

	function toggleMenu(e) {
		e.preventDefault();

		if ($('body').hasClass('nav-open')) {
			closeMenu();
		} else if (!$('body').hasClass('nav-open')) {
			openMenu();
		}
	}

	function openMenu() {
		LockScroll.lock();
		$('body').addClass('nav-open');
		isOpen = true;
	}

	function closeMenu() {
		LockScroll.unlock();
		$('body').removeClass('nav-open');
		isOpen = false;
	}

	return {init};
})();