export default (function() {

	function init(form, callback) {
		if (form.length) {
			preventRedirect(form, callback);
		}
	}

	function preventRedirect(form, callback) {
		$(form).submit((e) => {
			// Check if jQuery Validation plugin is loaded in external Spotler script
			if ($().valid) {
				// Check if form is valid
				const isValid = $(e.currentTarget).valid();

				if (isValid) {
					e.preventDefault();
					submitForm($(e.currentTarget), callback);
				}
			}
		});
	}

	function getUrlVars(url) {
		const vars = {};
		url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
			vars[key] = value;
		});

		return vars;
	}

	function submitForm(form, callback) {
		const formUrl = $(form).attr('action');
		const formid  = getUrlVars(formUrl)['formid'];
		const values  = $(form).serialize();
		const data    = `action=post_mailplus_form&formUrl=${formUrl}&formid=${formid}&${values}`;

		$.ajax({
			url : `${window.theme.ajax_url}`,
			type: 'post',
			data,
			beforeSend: () => {
				$(form).addClass('is-submitting');
			},
			success: (result) => {
				const data = JSON.parse(result);

				// Check if it's the confirmation page
				if (data && data.html) {
					$(form).hide().html(data.html).fadeIn(200);
					$(form).addClass('has-success');

					// Do callback
					if (callback) {
						callback($(form));
					}
				// Redirect to external page when url is set
				} else if (data && data.url) {
					window.location.assign(data.url);
				// Display error message
				} else if (data && data.errorMessage) {
					$(form).hide().html(data.errorMessage).fadeIn(200);
					$(form).addClass('has-error');
				}
			},
			error: (jqXHR, error) => {
				console.log(jqXHR.responseText, error);
				$(form).addClass('has-error');
			},
			complete: () => {
				$(form).removeClass('is-submitting');
				$('html, body').animate({
					scrollTop: $(form).offset().top
				}, 200);
			}
		});
	}

	return {
		init
	};
})();