export default (function() {
	let request;

	function init(searchEl) {
		const $wrapper = searchEl;
		const $form    = $wrapper.find('.js-search-form');
		const $reset   = $wrapper.find('.js-reset-search');
		const $search  = $wrapper.find('.js-main-search');

		// Check for value on init
		const inputValue = $search.val();
		if (inputValue.length > 0) {
			hasInputContent($wrapper);
		}

		// Reset value
		$reset.on('click', (e) => {
			e.preventDefault();
			$search.val('');
			noInputContent($wrapper);
		});

		// Fix iOS keyboard fixed positioning
		if (!$('body').hasClass('no-touch')) {
			$form.on('focus', 'input[name="s"]', () => {
				$('body').addClass('focus-fixed');
			}).on('blur', 'input[name="s"]', () => {
				$('body').removeClass('focus-fixed');
			});
		}

		// Search suggestions
		$search.keyup(() => {
			const inputValue = $search.val();

			delay(() => {
				if (inputValue.length > 0) {
					hasInputContent($wrapper);
					submitSearchForm(inputValue, $wrapper);
				} else if (inputValue.length < 1) {
					noInputContent($wrapper);
				}
			}, 1000);
		});

		// Search results
		// $form.on('submit', (e) => {
		// 	e.preventDefault();
		// 	e.stopPropagation();

		// 	const inputValue = $search.val();
		// 	submitSearchForm(inputValue);
		// });
	}

	const delay = (() => {
		let timer = 0;

		return (callback, ms) => {
			clearTimeout(timer);
			timer = setTimeout(callback, ms);
		};
	})();

	function hasInputContent(wrapper) {
		if (!$(wrapper).hasClass('has-input-content')) {
			$(wrapper).addClass('has-input-content');
		}
	}

	function noInputContent(wrapper) {
		if (request && request.abort) request.abort();
		$(wrapper).find('.js-search-results-list').empty();

		if ($(wrapper).hasClass('has-input-content')) {
			$(wrapper).removeClass('has-input-content');
		}
	}

	function submitSearchForm(searchValue, wrapper) {
		const $search  = $(wrapper).find('.js-main-search');
		const $results = $(wrapper).find('.js-search-results');
		const $list    = $(wrapper).find('.js-search-results-list');

		if (searchValue) {
			// Cancel any existing requests
			if (request && request.abort) request.abort();
			// Get results
			request = $.ajax({
				url : window.theme.ajax_url,
				type: 'post',
				data: {
					action     : 'load_search_results',
					security   : window.theme.ajax_nonce,
					searchvalue: searchValue
				},
				dataType: 'html',
				beforeSend: () => {
					$search.prop('disabled', true);
					$results.addClass('is-loading');
				},
				success: (data) => {
					$search.prop('disabled', false);
					$results.removeClass('is-loading');
					$list.html(data);
				},
				error: (jqXHR, error) => {
					console.log(jqXHR.responseText, error);
					$results.removeClass('is-loading');
				}
			});
		}
	}

	return {
		init,
	};
})();