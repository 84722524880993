import BreakpointSniffer from 'utils/BreakpointSniffer';

export default (function() {
	let _isLarge           = null;
	const _$dropdownToggle = $('.js-dropdown-toggle');
	const _$wrap           = $('.js-wrap-overlay');
	const transEndEvent    = 'transitionend MSTransitionEnd webkitTransitionEnd oTransitionEnd';
	const _$body           = $('body');

	function init() {
		_$dropdownToggle.each((i, e) => {
			$(e).on('click', (e) => {
				toggleDropdown(e);
			});
		});

		_$wrap.on('click', () => {
			if (_$body.hasClass('has-header-dropdown')) {
				closeDropdown($('.js-dropdown.is-open'));
			}
		})

		$(document).keyup((e) => {
			if (_$body.hasClass('has-header-dropdown') && e.keyCode === 27) {
				closeDropdown($('.js-dropdown.is-open'));
			}
		});

		$(window).on('resize', onResize);
		onResize();
	}

	function toggleDropdown(e) {
		const enableSM = $(e.target).data('enable-sm');

		if (_isLarge || enableSM) {
			e.preventDefault();
			const $dropdown = $(e.currentTarget).siblings('.js-dropdown');

			if ($('.js-dropdown.is-open').is($dropdown)) {
				// Close clicked dropdown if it's the one that's open
				closeDropdown($dropdown);
			} else {
				// Close any other open dropdowns, tweak a bit with transEnd soon.
				$('.js-dropdown.is-open').not($dropdown).on(transEndEvent, openDropdown($dropdown)).removeClass('is-open');
			}
		}
	}

	function openDropdown(dropdown) {
		if (!_$body.hasClass('has-header-dropdown')) {
			_$body.addClass('has-header-dropdown');
		}

		if (dropdown.hasClass('is-open')) {
			dropdown.removeClass('is-open');
		} else {
			dropdown.addClass('is-open');
			dropdown.find('input[type="search"]').focus();
		}
	}

	function closeDropdown(dropdown) {
		$(document).off('click');
		_$body.removeClass('has-header-dropdown');

		if (dropdown.hasClass('is-open')) {
			dropdown.removeClass('is-open');
		}
	}

	function onResize() {
		_isLarge = BreakpointSniffer.isBigger('lg');
		if (_isLarge) {
			if (_$body.hasClass('has-header-dropdown')) {
				closeDropdown($('.js-dropdown.is-open'));
			}
		}
	}

	return {
		init,
	};
})();