import HundredVhFix from 'utils/100vhFix';
import CategoryCarousel from 'modules/categoryCarousel';
import CategoryMenu from'modules/categoryMenu';
import ModelMenu from'modules/modelMenu';

export default {
	init() {
		HundredVhFix.init($('.js-wrap-overlay, .js-category-carousel'));
		CategoryMenu.init($('.js-menu-cat-nav'));
		ModelMenu.init($('.js-model-menu-nav'));
		CategoryCarousel.init($('.js-category-gallery'));
	}
};
