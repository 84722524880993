import BreakpointSniffer from 'utils/BreakpointSniffer';

export default (function() {
	let _$menu;
	let _$close;
	let _$open;

	function init(menu) {
		_$menu   = menu;
		_$close  = _$menu.find('.js-cat-menu-close');
		_$open   = _$menu.find('.js-cat-menu-open');

		_$open.on('click', () => {
			_$menu.addClass('cat-menu-open');
		})

		_$close.on('click', () => {
			_$menu.removeClass('cat-menu-open');
		})

		if (!BreakpointSniffer.isBigger('lg') && !_$menu.hasClass('is-mobile-active')) {
			addMobileMenu();
		}

		$(window).on('resize', onResize);
	}

	function addMobileMenu() {
		_$menu.hide().addClass('is-mobile-active').show();
	}

	function removeMobileMenu() {
		_$menu.hide().removeClass('is-mobile-active').show();
	}

	function onResize() {
		if (!BreakpointSniffer.isBigger('lg') && !_$menu.hasClass('is-mobile-active')) {
			addMobileMenu();
		}
		else if (BreakpointSniffer.isBigger('lg') && _$menu.hasClass('is-mobile-active')) {
			removeMobileMenu();
		}
	}

	return {
		init
	};
})();