export default (function() {

	function init() {
		$(document).on('click', e => {
			if($(e.target).hasClass('js-remove-alert')) {
				$(e.target).closest('div').remove();
			}
		})
	}

	return {
		init,
	};
})();