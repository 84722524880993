export default (function() {
	let _el;
	let _template;
	let _wrapper;
	let _content;
	let _close;

	function init(el, templateEl) {
		_el = el;

		const templateString = $(templateEl).text();
		_template = window._.template(templateString);
		_wrapper  = $(_el).find('[data-type="wrapper"]');
		_content  = $(_el).find('[data-type="content"]');
		_close    = $(_el).find('.js-close-modal');

		_close.on('click', e => {
			e.preventDefault();

			$(_el).modal('hide');
		});
	}

	async function open(dealer) {
		_content.html('');
		$(_el).modal('show');

		const { DealerId } = dealer;
		dealer.DealerPage = false;

		try {
			const DealerPage = await getDealerPage(DealerId);
			dealer.DealerPage = DealerPage;
		} catch (error) {
			console.error(error);
		}

		const template = createTemplate(dealer);
		_content.html(template);
	}

	async function getDealerPage(dealerId) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.theme.ajax_url,
				type: 'POST',
				data: {
					action: 'get_dealer_page',
					security: window.theme.ajax_nonce,
					dealerId
				},
				dataType: 'json',
				beforeSend: () => {
					_wrapper.addClass('is-loading');
				},
				success : response => {
					if (response?.success) {
						resolve(response?.data)
					} else {
						resolve(false);
					}
				},
				error: (_, error) => {
					reject(error);
				},
				complete: () => {
					_wrapper.removeClass('is-loading');
				}
			});
		});
	}

	function createTemplate(dealer) {
		const directions = `${dealer.Dealer} ${dealer.Straat} ${dealer.Plaats}`;
		dealer.Directions = `http://maps.google.com/maps?q=${encodeURIComponent(directions)}`;

		return _template(dealer);
	}

	return {
		init,
		open
	}
})()