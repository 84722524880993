import brochureRequest from 'modules/brochureRequest';
import submitMailplusForms from 'modules/submitMailplusForms';


export default {
	init() {
		brochureRequest.init();
		submitMailplusForms.init($('form.mpForm'), brochureRequest.success);
	},
};
