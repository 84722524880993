import 'select2';


export default (function() {

	function init(select) {
		$(select).selectWoo({
			dropdownParent         : $('.js-wrap-overlay'),
			dropdownAutoWidth      : true,
			width                  : 'auto',
			minimumResultsForSearch: -1
		}).each((i, e) => {
			$(e).data('select2').$container.addClass('c-dropdown');
		}).on('select2:select', (e) => {
			// Force selected value update
			const text = e.params.data.text;
			$(e.currentTarget).data('select2').$selection.find('span.select2-selection__rendered').text(text);
		});
	}

	return {
		init
	};
})();