import 'slick-carousel';
import 'sass-to-js/js/dist/sass-to-js.min.js';

export default (function() {

	function init(carousel) {
		const _breakpoints = $('.js-breakpoint-sniffer').sassToJs({pseudoEl: '::after', cssProperty: 'content'});

		if (carousel.length > 0) {
			const _$nav = $(carousel).siblings('.js-carousel-nav');
			const _$counter = _$nav.find('.js-carousel-counter');

			$(carousel).on('init', function(event, slick){
				setCounter(_$counter, slick.currentSlide, slick.slideCount);
			});
			carousel.slick({
				lazyLoad      : 'ondemand',
				slidesToShow  : 4,
				slidesToScroll: 2,
				centerMode    : false,
				variableWidth : true, // For off-setting first slide
				cssEase       : 'cubic-bezier(0.15, 0, 0, 1)',
				infinite      : false,
				speed         : 500,
				fade          : false,
				dots          : false,
				appendArrows: _$nav,
				prevArrow   : '<a class="slick-prev slick-arrow" href="#"><i class="icon icon-chevron-left" aria-hidden="true"></i></a>',
				nextArrow   : '<a class="slick-next slick-arrow" href="#"><i class="icon icon-chevron-right" aria-hidden="true"></i></a>',
				responsive    : [
					{
						breakpoint: parseInt(_breakpoints.xl, 10),
						settings  : {
							slidesToShow  : 3,
							slidesToScroll: 2,
							centerMode    : false,
							variableWidth : true  // For off-setting first slide
						}
					},
					{
						breakpoint: parseInt(_breakpoints.md, 10),
						settings  : {
							slidesToShow  : 2,
							slidesToScroll: 2,
							centerMode    : false,
							variableWidth : false
						}
					}
				]
			});
			$(carousel).on('beforeChange', function(event, slick, currentSlide, nextSlide){
				setCounter(_$counter, nextSlide, slick.slideCount);
			});
		}
	}

	function setCounter(el, count, total) {
		$(el).text(`${count + 1} / ${total}`);
	}

	return {
		init,
	};
})();
