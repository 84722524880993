import 'slick-carousel';
import 'sass-to-js/js/dist/sass-to-js.min.js';
import VideoModal from 'modules/VideoModal';
import BreakpointSniffer from 'utils/BreakpointSniffer';


export default (function() {
	let _$carousel;
	let _$nav;
	let _$buttons;
	let _breakpoints;
	let hasSlick = false;

	function init(carousel) {
		_$carousel   = carousel;
		_$nav        = carousel.siblings('.js-category-gallery-nav');
		_breakpoints = $('.js-breakpoint-sniffer').sassToJs({pseudoEl: '::after', cssProperty: 'content'});
		_$buttons    = $('.js-bike-details-button');

		$(window).on('resize orientationchange', onResize);
		onResize();
	}

	function initSlider(carousel, nav) {
		// Bikes
		carousel.not('.slick-initialized').on('init', (slick) => {
			const _play = $(slick.currentTarget).find('.slick-current').find('.js-play-video');
			playVideo(_play);
		}).slick({
			slidesToShow  : 1,
			slidesToScroll: 1,
			cssEase       : 'cubic-bezier(0.15, 0, 0, 1)',
			infinite      : true,
			speed         : 500,
			fade          : false,
			dots          : false,
			arrows        : false,
			asNavFor      : nav
		}).on('afterChange', (event, slick, currentSlide) => {
			const _play = $(slick.$slides.get(currentSlide)).find('.js-play-video');
			playVideo(_play);
		});

		// Thumbs
		nav.not('.slick-initialized').slick({
			slidesToShow  : 8,
			slidesToScroll: 1,
			asNavFor      : carousel,
			dots          : false,
			arrows        : true,
			prevArrow     : '<a class="b-category-carousel__prev" href="#"><i class="icon icon-chevron-left" aria-hidden="true"></i></a>',
			nextArrow     : '<a class="b-category-carousel__next" href="#"><i class="icon icon-chevron-right" aria-hidden="true"></i></a>',
			centerMode    : false,
			focusOnSelect : true,
			responsive    : [
				{
					breakpoint: parseInt(_breakpoints.lg, 10),
					settings: {
						slidesToShow: 8
					}
				},
				{
					breakpoint: parseInt(_breakpoints.md, 10),
					settings: {
						slidesToShow: 8
					}
				},
				{
					breakpoint: parseInt(_breakpoints.sm, 10),
					settings: {
						slidesToShow: 8
					}
				}
			]
		});

		// Re-position button
		_$buttons.css({
			'position': 'relative',
			'left'    : '',
			'right'   : '',
			'top'     : ''
		});

		hasSlick = true;
	}

	function playVideo(play) {
		VideoModal.init(play, $('#product-video'));
	}

	function onResize() {
		const height    = window.innerHeight;
		const btnHeight = _$buttons.outerHeight();

		if (!BreakpointSniffer.isBigger('lg')) {
			_$buttons.css({
				'position': 'fixed',
				'left'    : 0,
				'right'   : 0,
				'top'     : height - btnHeight
			});

			if (hasSlick) {
				_$carousel.slick('unslick');
				// _$nav.slick('unslick'); // not always firing
				hasSlick = false;
			}

		} else if (!hasSlick && BreakpointSniffer.isBigger('lg') && _$carousel.length > 0) {
			initSlider(_$carousel, _$nav);

		} else if (hasSlick) {
			_$carousel.slick('resize');
			_$nav.slick('resize');
		}
	}

	return {
		init
	};
})();
