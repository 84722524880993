import BreakpointSniffer from 'utils/BreakpointSniffer'

export default (function() {
	let $btns;
	let $tabs;

	function init(btns, tabs) {
		if ($(btns).length <= 0 ) return null;

		$(btns).click(onClick);
		$btns = btns;
		$tabs = tabs;

		$(window).on('resize', onResize);
		onResize();
	}

	function onClick(e) {
		e.preventDefault();

		const view = $(this).data('view');

		$btns.removeClass('is-active');
		$(this).addClass('is-active');

		$tabs.hide();
		$tabs.each((i, el) => {
			if ($(el).data('view') == view) {
				$(el).show();
			}
		});

		$(window).trigger('map-resize');
	}

	function reset() {
		$tabs.each((i, el) => {
			$(el).css({display: ''});
		});
	}

	function onResize() {
		if (BreakpointSniffer.isBigger('lg')) {
			reset();
		}
	}

	return {
		init
	};
})();