export default (function() {
	function init() {
		const parents = $('[data-anchor-to]');
		if (parents.length > 0) {
			$(parents).each((i, el) => {
				const targetQuery = $(el).data("anchor-to");
				$(el).on("click", e => {
					e.preventDefault();
					const target = $(`[data-anchor-from=${targetQuery}]`);
					if (target.length > 0) {
						target[0].scrollIntoView({ behavior: 'smooth' });
					} else {
						console.log(`Missing target wtih attribute: ${targetQuery}`);
					}
				});
			});
		}
	}

	return {
		init,
	};
})();