import { getCookie, setCookie } from 'utils/Cookies';


export default (function(){
	function init() {
		const hasCancelled = getCookie('locator_cancelled');

		if (!hasCancelled) {
			$.ajax({
				url : window.theme.ajax_url,
				type: 'post',
				data: {
					action  : 'get_locator',
					security: window.theme.ajax_nonce,
				},
				dataType: 'html',
				beforeSend: () => {
				},
				success: (response) => {
					$('body').append(response);
					setTimeout(() => {
						$('.js-locator').addClass('show');
					}, 10);
					initCancel();
				},
				error: (jqXHR, error) => {
				}
			});
		}
	}

	function initCancel() {
		$('.js-locator-cancel').click(() => {
			setCookie('locator_cancelled', 1, 365);
			$('.js-locator').removeClass('show');
		});
	}

	return {init};
})();