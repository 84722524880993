import SelectCategory from 'modules/selectCategory';
import PostFilter from 'modules/postFilter';
import PressCarousel from 'modules/pressCarousel';
import VideoModal from 'modules/VideoModal';

export default {
	init() {
		SelectCategory.init($('#select-category'));
		PressCarousel.init();
		VideoModal.init($('.js-play-video'), $('#press-video'));
		PostFilter.init('.js-post-container', '.js-post-filter');
	},
};
