export default (function() {

	let _$sideNav = null;
	let _offset = 150;

	function init() {
		_$sideNav = $('#side-nav');

		if (_$sideNav && _$sideNav.length) {
			activate();
		}
	}

	function activate() {
		$('body').scrollspy({
			target: '#side-nav',
			offset: _offset
		});

		_$sideNav.find('.nav-link').on('click', e => {
			e.preventDefault();

			$('html, body').animate({
				scrollTop: $($.attr(e.target, 'href')).offset().top - _offset
			}, 500);
		})
	}

	return {
		init
	};

})();