import 'slick-carousel';

export default (function() {

	function init(carousel) {
		if (carousel.length > 0) {
			const _$arrows = $(carousel).parent().find('.js-carousel-arrows');
			const _$dots   = $(carousel).parent().find('.js-carousel-dots');

			carousel.slick({
				// autoplay    : true,
				dots        : true,
				cssEase     : 'cubic-bezier(0.15, 0, 0, 1)',
				infinite    : false,
				speed       : 500,
				fade        : false,
				appendArrows: _$arrows,
				appendDots  : _$dots,
				prevArrow   : '<a class="c-button c-button--white slick-prev slick-arrow" href="#"><i class="icon icon-chevron-left" aria-hidden="true"></i></a>',
				nextArrow   : '<a class="c-button c-button--white slick-next slick-arrow" href="#"><i class="icon icon-chevron-right" aria-hidden="true"></i></a>'
			});
		}
	}

	return {
		init,
	};
})();
