import storeDetails from 'modules/storeDetails';

export default (function() {
	const _dealerTypes = window.theme.dealer_types;
	let _template;
	let _sections;
	let _placeholder;

	function init(el, templateEl) {
		const templateString = $(templateEl).text();
		_template = window._.template(templateString);
		_sections = [...$(el).find('[data-section]')];
		_placeholder = $('.js-store-placeholder');
	}

	function create(dealers) {
		cleanSections();
		_placeholder.hide();

		const closeSection = _sections.find(section => $(section).data('section') == 'close');
		const featuredSection = _sections.find(section => $(section).data('section') == 'featured');
		const sortedDealers = findFeaturedDealers(dealers);

		addToSection(sortedDealers.featured, featuredSection);
		addToSection(sortedDealers.closeby, closeSection)

		checkSections();
	}

	function findFeaturedDealers(dealers) {
		const featuredDealers = [];

		
		const isFeatured = (dealer, shopType) => {
			return dealer.properties.Dealertype == shopType && dealer.properties.distance < 40;
		}
		
		const isExpCenter = dealer => {
			return dealer.properties.Dealertype == 'Experience-Center' && dealer.properties.distance < 50;
		}

		if (featuredDealers.length < 2) {
			for (let i = 0; i < dealers.length; i++){
				if (featuredDealers.length < 2) {
					if (isFeatured(dealers[i], 'Shop-in-Shop')) {
						featuredDealers.push(dealers[i]);
					}
				} else {
					break;
				}
			}
		}
		
		if (featuredDealers.length < 2) {
			for (let i = 0; i < dealers.length; i++){
				if (featuredDealers.length < 2) {
					if (isExpCenter(dealers[i])) {
						featuredDealers.push(dealers[i]);
					}
				} else {
					break;
				}
			}
		}

		if (featuredDealers.length < 2) {
			for (let i = 0; i < dealers.length; i++){
				if (featuredDealers.length < 2) {
					if (isFeatured(dealers[i], 'XL')) {
						featuredDealers.push(dealers[i]);
					}
				} else {
					break;
				}
			}
		}

		const sortedDealers = {
			featured: featuredDealers,
			closeby: dealers.filter(dealer => !featuredDealers.includes(dealer))
		};

		return sortedDealers;
	}

	function createTemplate(dealer) {
		dealer.distance = Math.ceil(dealer.distance);

		return _template(dealer);
	}

	function addToSection(elements, section) {
		const list = getList(section);

		elements.forEach(element => {
			$(list).append(createTemplate(element.properties));
		})
	}

	function getList(section) {
		return $(section).find('[data-list]');
	}

	function cleanSections() {
		_sections.forEach(section => {
			const list = getList(section);

			list.empty();
		});
	}

	function checkSections() {
		_sections.forEach(section => {
			const list = getList(section);

			if ($(list).children().length > 0) {
				$(section).show();
			} else {
				$(section).hide();
			}
		})
	}

	function filter(filter) {
		console.log(filter);
	}

	return {
		init,
		create,
		filter
	};
})();