export default (function() {
	const search = $('.js-faq-search');
	const faqs   = $('.js-faq-group');
	const qas    = $('.js-faq-qa');

	function init() {
		search.keyup(() => {
			const filter = search.val();
			filterFaqs(filter);
		});
	}

	// Loop through all Q&A's, and hide those who don't match the search query
	function filterFaqs(filter) {
		qas.show().unmark();
		faqs.show().unmark();

		if (filter) {
			qas.mark(filter, {
				className: 'is-marked',
				done: () => {
					qas.not(":has(mark)").hide();
					faqs.not(":has(mark)").hide();
				}
			});
		}
	}

	return {
		init,
	};
})();