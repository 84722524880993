import 'slick-carousel';

export default (function() {
	let _$carousel = null;

	function init() {
		const $carousel    = $('.js-image-carousel');
		_$carousel = $carousel;

		if ($carousel.length > 0) {
			$carousel.each((index, carousel) => {
				let _$nav = $(carousel).siblings('.js-carousel-nav');
				let _$counter = _$nav.find('.js-carousel-counter');

				$(carousel).on('init', function(event, slick){
					setCounter(_$counter, slick.currentSlide, slick.slideCount);
				});

				$(carousel).slick({
					cssEase       : 'cubic-bezier(0.15, 0, 0, 1)',
					infinite      : false,
					speed         : 500,
					fade          : false,
					dots          : false,
					adaptiveHeight: false,
					variableWidth : true,
					appendArrows: _$nav,
					prevArrow   : '<a class="slick-prev slick-arrow" href="#"><i class="icon icon-chevron-left" aria-hidden="true"></i></a>',
					nextArrow   : '<a class="slick-next slick-arrow" href="#"><i class="icon icon-chevron-right" aria-hidden="true"></i></a>',
				});
				$(carousel).on('beforeChange', function(event, slick, currentSlide, nextSlide){
					setCounter(_$counter, nextSlide, slick.slideCount);
				});
			});
		}

		onResize();
		$(window).on('resize', () => {
			onResize();
		});
		imageLoaded();
	}

	function onResize() {
		if (!_$carousel) {
			return;
		}

		_$carousel.each(function(){
			$(this).css({
				'width' : '',
				'height': ''
			});
		});

		_$carousel.each(function() {
			$(this).css({
				'width' : $(this).parent().width()
			});
		});
		constrainHeight();
	}

	function imageLoaded() {
		_$carousel.each(function(){
			const imgs = $(this).find('img');
			$(imgs).each(function() {
				$(this).on('load', constrainHeight);
			})
		});
	}

	function constrainHeight() {
		if (!_$carousel) {
			return;
		}

		_$carousel.each(function(){
			const imgs = [...$(this).find('img')];
			$(imgs).css({
				'min-height' : '0px',
				'max-height' : ''
			});
		});
	}
	function setCounter(el, count, total) {
		$(el).text(`${count + 1} / ${total}`);
	}
	return {
		init,
	};
})();
