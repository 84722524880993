export default (function() {
	function init(back) {
		if ($(back).length > 0) {
			$(back).on('click touch', e => {
				window.history.go(-1);
				return false;
			});
		}
	}

	return { init };
})();