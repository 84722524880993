export default (function() {

	function init() {
		const _$link   = $('.js-awards-load');
		const _$awards = $('.js-awards-container');

		_$link.on('click', e => {
			e.preventDefault();
			_$awards.removeClass('has-loader');
			_$link.remove();
		})
	}

	return {
		init,
	};
})();