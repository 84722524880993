/* npm dependencies */
import 'bootstrap/js/src/util';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/tooltip';
import 'bootstrap/js/src/scrollspy';
import 'lazysizes';
import 'lazysizes/plugins/aspectratio/ls.aspectratio.js';
import 'lazysizes/plugins/bgset/ls.bgset.js';
import 'mark.js/dist/jquery.mark.js';

/* Local dependencies */
import 'utils/Easing';
import 'utils/Tooltips';
import select2 from 'utils/Select2';
import touchSupport from 'utils/TouchSupport';
import HundredVhFix from 'utils/100vhFix';
import lazyLoading from 'utils/LazyLoading';
import floatingLabels from 'utils/floatingLabels';
import scrollDetect from 'utils/scrollDetect';

import cookieConsent from 'modules/cookieConsent';
import navHeader from 'modules/navHeader';
import mainMenu from 'modules/mainMenu';
import imageCarousel from 'modules/imageCarousel';
import postsCarousel from 'modules/postsCarousel';
import loader from 'modules/Loader';
import video from 'modules/Video';
import back from 'modules/back';
import numberInput from 'modules/numberInput';
import nearestDealers from 'modules/nearestDealers';
import revealAwards from 'modules/revealAwards';
import readMoreCollapse from 'modules/readMoreCollapse';
import sideNavScroll from 'modules/sideNavScroll';
import removeAlerts from 'modules/removeAlerts';
import salesforceFormSubmission from 'modules/salesforceFormSubmission';
import languageSelector from 'modules/languageSelector';
import locator from 'modules/locator';
import AwardsCarousel from 'modules/awardsCarousel';


export default {
	// JavaScript to be fired on all pages
	initOnce() {
		HundredVhFix.init();
		navHeader.init();
		mainMenu.init($('.js-header'));
		cookieConsent.init();
		sideNavScroll.init();
	},
	init() {
		AwardsCarousel.init($('.js-awards-carousel'));
		select2.init($('select'));
		scrollDetect.init();
		touchSupport.init();
		imageCarousel.init();
		postsCarousel.init($('.js-post-carousel'));
		lazyLoading.init();
		loader.init();
		video.init();
		nearestDealers.init($('#nearest-dealers-map'));
		numberInput.init();
		revealAwards.init();
		readMoreCollapse.init();
		removeAlerts.init();
		floatingLabels.init($('.nf-form-cont'), 'div:not(.pikaday__container)');
		floatingLabels.init($('form.mpForm'), '.mpQuestionTable');
		back.init($('.js-back'));
		languageSelector.init($('.js-lang-selector'), $('.js-lang-window'));
		locator.init();
		salesforceFormSubmission.init();
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
