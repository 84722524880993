import { getCookie, setCookie } from 'utils/Cookies';


export default (function() {
	const cookiePopup    = $('#cookie-consent');
	const openSettings   = $('.js-cookie-open-settings');
	const cookieConsent  = cookiePopup.find('.js-cookie-popup-consent');
	const cookieSettings = cookiePopup.find('.js-cookie-popup-settings');
	const cookieMore     = cookiePopup.find('.js-cookie-settings');
	const cookieAccept   = cookiePopup.find('.js-cookie-accept');
	const cookieOption   = cookiePopup.find('.js-cookie-option');
	const cookieCancel   = cookiePopup.find('.js-cookie-cancel');
	const cookieSave     = cookiePopup.find('.js-cookie-save');
	let cookieConsentValue;


	function init() {
		cookieConsentValue = getCookie('cookie_consent');

		// Show/hide popup when cookie is (not) set
		if (cookieConsentValue) {
			setCookieOptions(cookieConsentValue);
		} else {
			openCookiePopup();
		}

		// Open settings from footer link
		$(openSettings).on('click tap', e => {
			e.preventDefault();
			cookieConsent.removeClass('is-active');
			cookieSettings.addClass('is-active');
			openCookiePopup();
		});

		// Show settings from consent screen
		$(cookieMore).on('click tap', e => {
			e.preventDefault();
			cookieConsent.removeClass('is-active');
			cookieSettings.addClass('is-active');
		});

		// Accept default cookie settings
		$(cookieAccept).on('click tap', e => {
			e.preventDefault();
			saveCookieSettings();
		});

		// Cancel settings
		$(cookieCancel).on('click tap', e => {
			e.preventDefault();
			if (cookieConsentValue) {
				closeCookiePopup();
			} else {
				cookieConsent.addClass('is-active');
				cookieSettings.removeClass('is-active');
			}
		});

		// Save custom cookie settings
		$(cookieSave).on('click tap', e => {
			e.preventDefault();
			saveCookieSettings();
		});
	}

	// Open Modal
	function openCookiePopup() {
		$(cookiePopup).modal({
			show    : true,
			backdrop: 'static',
			keyboard: false
		});
	}

	// Close Modal
	function closeCookiePopup() {
		$(cookiePopup).modal('hide');
	}

	// Save and write value to cookie
	function saveCookieSettings() {
		const options = getCookieOptions();
		setCookie('cookie_consent', options, 365);
		onCookieChange(cookieConsentValue, options);
		cookieConsentValue = options;

		closeCookiePopup();
	}

	// Get current selection
	function getCookieOptions() {
		let selection = '';

		$(cookieOption).not(':disabled').each((i, el) => {
			if ($(el).is(':checked')) {
				selection += $(el).val();
			}
		});

		return (selection) ? selection : 'none';
	}

	// Set selection based upon cookie value
	function setCookieOptions(options) {
		cookieConsent.hide();
		$(cookieOption).not(':disabled').each((i, el) => {
			const checked = options.indexOf($(el).val()) > -1;
			$(el).prop('checked', checked);
		});
	}

	// Push event when cookie has changed
	function onCookieChange(oldVal, newVal) {
		if (oldVal !== newVal) {
			if ((!window.wp.wp_env || window.wp.wp_env === 'production') && !window.wp.is_admin) {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					'event'          : 'cookieConsentChanged',
					'oldConsentLevel': oldVal,
					'newConsentLevel': newVal
				});
			} else {
				console.log('GTM event: cookie consent has changed');
			}
		}
	}

	return {
		init
	};
})();