/** @license

			...oooOOOOoo.                                                      oO.                                    o.
	 ..oOOOOOO88OoooO88O.                                                   o8O.                                   o88.
	 .....  .O88O.oO888O.                                  ...        .o   o88o                                   .888O
			 .O888888Oo..                                .oOOo..     .OOO.  o88o                                   .O888o
			o88888888Ooo.                             .oOOO.         oo.   o88O                                   .O888o
		 .O88OoooooooOOOo.     .ooo....oOOo....oOooo88o. .o..    .o.    o88O.    oOoooOo.    .oo .oOo.     ...  O888O
		.O88o          o88o  .O8OO88ooO88OO8OooO8888O. .O8888o  .O8o   o888.   .OOoo8888o  .o888O8888O   .O8O..O888O.     ..
	 .O88o         .O888O .O8o o8O .O8o.O8.  o888o  o88888Oo.o88O   o888o  .o8O.oO888o  .oO8888888O.  o88o .O888O.   .ooo.
	.88O.        .o8888O..O8o o88o.O8o.O8O  .88O.  o88o....O888O. .O888o .oO8O.o8888o ...O888oo88O  .O88o.oO888O. .oOOo.
 .88O.       .o8888O.  O8O.O88o o8OoO8O. .O8O.   O8o ..OOoO88ooOOoO88ooO888OOO.o8O.oo.O88O. o8OooO888ooO8Ooo8OoO88O.
 .Oo.      .oO88Oo.    .O888Oo  oO88Oo   .OO.    .oooOoo. .O8OO.   .OOo.oO8Oo.  .oo. .OOo   .oOOo..O88OO.   oO88Oo
				 .O888Oo        ..o.     ...                .       ..            .                         ...       ..
			.oO88Oo.
	 .oOOOOo.
 .oOOo.                This piece of technology has been lovingly handcrafted by Booreiland.amsterdam
.ooo.

*/

// import local dependencies
import CONFIG from 'core/Config';
import Router from 'utils/Router';
import common from 'routes/common';
import frontPage from 'routes/frontPage';
import taxProductCat from 'routes/taxProductCat';
import templateBikeSerie from 'routes/templateBikeSerie';
import templateBrochure from 'routes/templateBrochure';
import templateWizard from 'routes/templateWizard';
import templateComparer from 'routes/templateComparer';
import templateFaq from 'routes/templateFaq';
import templateStoreLocator from 'routes/templateStoreLocator';
import templateAbout from 'routes/templateAbout';
import templateNews from 'routes/templatePostsList';
import templatePressReleases from 'routes/templatePostsList';
import templatePressKit from 'routes/templatePostsList';
import templatePress from 'routes/templatePress';
import taxNewsCategory from 'routes/templatePostsList';
import taxPressCategory from 'routes/templatePostsList';
import taxPressKitCategory from 'routes/templatePostsList';
import templateAgenda from 'routes/templatePostsList';
import templateAwards from 'routes/templatePostsList';
import taxAwardsYear from 'routes/templatePostsList';
import searchResults from 'routes/searchResults';
import singleNews from 'routes/singlePost';
import singlePressRelease from 'routes/singlePost';
import woocommerceCart from 'routes/cart';

/** Populate Router instance with DOM routes */
const routes = new Router({
	// All pages
	common,
	// Front Page
	home: frontPage,
	// Template Bike Serie
	templateBikeSerie,
	// Product Category
	taxProductCat,
	// Cart
	woocommerceCart,
	// Brochure Request Template
	templateBrochure,
	// Wizard Template
	templateWizard,
	// Bike Comparer Template
	templateComparer,
	// FAQ Template
	templateFaq,
	// Store Locator Template
	templateStoreLocator,
	// Template About
	templateAbout,
	// Template Press
	templatePress,
	// Posts List
	templateNews,
	templatePressKit,
	templatePressReleases,
	taxNewsCategory,
	taxPressKitCategory,
	taxPressCategory,
	templateAgenda,
	templateAwards,
	taxAwardsYear,
	// Search results
	searchResults,
	// Singles
	singleNews,
	singlePressRelease
});

// Copyright Console
window['console']['log'](`%c${CONFIG.COPYRIGHT}`, 'background-color: #fee359; color: #121212; font-family: courier, monospace; font-size: 12px; font-weight: bold; padding: 1px;');

// Load Events
jQuery(document).ready(() => routes.loadEvents());