import mapboxgl from 'mapbox-gl';
import storeDetails from 'modules/storeDetails';

export default (function() {
	let dealersSource;
	let dealerTypes;
	let mapbox;
	let mapTarget;
	let observer;
	let _dealers = [];
	const layerIDs = [];

	function init(mapboxEl) {
		mapboxgl.accessToken = window?.theme?.mapbox_at;
		dealerTypes = window?.theme?.dealer_types;

		if (!observer) {
			startObserver();
		}

		if (mapboxEl?.length > 0) {
			mapTarget = mapboxEl[0];
			mapTarget.callback = loadMap;
			observer.observe(mapTarget);
		}
	}

	function loadMap() {
		storeDetails.init($('#store-modal'), $('#store-modal-template'));

		initmapboxBox(mapTarget, () => {
			loadDealers(mapTarget, dealerTypes);
			getUserLocation();
		});
	}

	function startObserver() {
		observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.callback();
					observer.unobserve(mapTarget);
				}
			});
		}, {
			rootMargin: '0%',
		});
	}

	/**
	 * Get user location
	 * Will return ['granted', 'prompt', 'denied']
	 */
	function getUserLocation() {
		navigator?.permissions.query({name:'geolocation'}).then(result => {
			if (result?.state === 'granted') {
				navigator?.geolocation?.getCurrentPosition(setUserLocation);
				$(mapTarget).find('.mapboxgl-ctrl-geolocate').hide();
			} else if (result?.state === 'prompt') {
				navigator?.geolocation?.getCurrentPosition(setUserLocation);
			} else if (result?.state == 'denied') {
				$(mapTarget).find('.mapboxgl-ctrl-geolocate').show();
			}
		});
	}

	// Go to user location
	function setUserLocation(position = {}) {
		const {
			coords: {
				latitude = null,
				longitude = null
			} = {}
		} = position;

		mapbox.flyTo({
			center: [longitude, latitude],
			zoom: 12
		});
	}

	// Load Dealers from SalesForce API
	function loadDealers(mapboxEl, dealerTypes) {
		$.ajax({
			url: window.theme.ajax_url,
			type: 'POST',
			data: {
				action: 'get_dealers'
			},
			dataType: 'json',
			beforeSend: () => {
				$(mapboxEl).addClass('is-loading');
			},
			success : response => {
				if (response?.success) {
					dealersSource = response?.data;
					addDealers(dealerTypes, dealersSource);
				} else {
					const error = response?.data?.[0];
					alert(error?.message);
				}
			},
			error: (jqXHR, error) => {
				console.log(error);
			},
			complete: () => {
				$(mapboxEl).removeClass('is-loading');
			}
		});
	}

	function initmapboxBox(mapboxEl, callback) {
		mapbox = new mapboxgl.Map({
			container: mapboxEl,
			style    : 'mapbox://styles/mapbox/light-v9',
			minZoom  : 2,
			maxZoom  : 16,
			center   : [5.115485, 51.579226],
			zoom     : 6
		});

		// Force resize
		mapbox.on('load', () => {
			mapbox.resize();
			callback();
		});

		// Add Geolocate control to the mapbox.
		mapbox.addControl(new mapboxgl.GeolocateControl({
			positionOptions: {
				enableHighAccuracy: true
			},
			trackUserLocation: true
		}), 'bottom-right');

		// Resize listener
		$(window).on('mapbox-resize', () => {
			mapbox.resize();
		});
	}

	function addDealers(dealerTypes, dealers) {
		mapbox.addSource('dealers', {
			'type'          : 'geojson',
			'data'          : dealers,
			'cluster'       : true,
			'clusterMaxZoom': 10,
			'clusterRadius' : 50
		});

		mapbox.addSource('search-result', {
			'type': 'geojson',
			'data': {
				'type'    : 'FeatureCollection',
				'features': []
			}
		});

		mapbox.addLayer({
			id    : 'clusters',
			type  : 'circle',
			source: 'dealers',
			filter: ['has', 'point_count'],
			paint : {
				'circle-color' : '#fa530b',
				'circle-radius': ['step', ['get', 'point_count'], 20, 25, 30, 50, 40]
			}
		});

		mapbox.addLayer({
			id    : 'cluster-count',
			type  : 'symbol',
			source: 'dealers',
			filter: ['has', 'point_count'],
			layout: {
				'text-field': '{point_count_abbreviated}',
				'text-font' : ['Arial Unicode MS Bold'],
				'text-size' : 18
			},
			paint: {
				'text-color': '#fff'
			}
		});

		mapbox.addLayer({
			'id'    : 'point',
			'source': 'search-result',
			'type'  : 'circle',
			'paint' : {
				'circle-radius': 10,
				'circle-color': '#000'
			}
		});

		// Get unique dealer types
		if (dealers.features) {
			_dealers = dealers.features;

			_dealers.forEach(dealer => {
				const shopType = dealerTypes.find(type => dealer.properties.Dealertype == type.name);
				dealer.properties.shopLabel = dealer.properties.Dealertype != 'Normal' ? shopType.label || dealer.properties.Dealertype : '';
				dealer.properties.shopTag = shopType.tagline;
			})

			const uniqueDealers = dealers.features ? dealers.features.reduce((arr, el) => {
				const type = el.properties['Dealertype'];

				if (arr.includes(type)) {
					return arr;
				}
				arr.push(type);

				return arr;
			}, []) : null;

			// Add layer & image for each dealer type
			uniqueDealers.forEach((type) => {
				const typeSlug   = type.replace(/\s/g, '-').toLowerCase();
				const layerID    = `qwic-dealer-${typeSlug}`;
				const dealerType = dealerTypes.filter((obj) => {
					return obj.name === type;
				});

				// Get icon either from uploads or assets fallback
				let image;
				if (dealerType[0].icon) {
					image = dealerType[0].icon;
				} else {
					image = require(`images/${layerID}.png`);
				}

				layerIDs.push(layerID);
				mapbox.loadImage(image, (error, icon) => {
					if (error) throw error;
					if (icon) {
						mapbox.addImage(`${layerID}`, icon);
						if (!mapbox.getLayer(layerID)) {
							let filter = [
								'all',
								['!', ['has', 'point_count']],
								['==', ['geometry-type'], 'Point'],
								['==', ['get', 'Dealertype'], type]
							];

							mapbox.addLayer({
								id    : layerID,
								type  : 'symbol',
								source: 'dealers',
								layout: {
									'icon-image'           : `${layerID}`,
									'icon-allow-overlap'   : true,
									'icon-ignore-placement': true,
									'text-allow-overlap'   : true, // doesn't make sense I know
									'icon-size'            : 0.5,
									'icon-padding'         : 20 // offset for hovering to popup
								},
								filter
							});

							mapbox.on('click', layerID, (e) => {
								if (e.features[0]) {
									const selectedDealer = _dealers.find(dealer => dealer.properties.DealerId == e.features[0].properties.DealerId);
									storeDetails.open(selectedDealer.properties);
								}
							});
						}
					}
				});
			});
		}
	}

	return {
		init,
	};
})();