/* global FB */
/* eslint-disable no-useless-escape */
export default (function() {

	function init(play, modal) {
		if (play.length > 0) {
			const videoSrc  = play.data('video-src');

			play.on('click', e => {
				e.preventDefault();
				openModal(modal, videoSrc);
			});
		}
	}

	function openModal(modal, videoSrc) {
		$(modal).modal('show');

		// Check if it's facebook video
		const regex = new RegExp('^(?:(?:https?:)?\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9\.]+\/videos\/(?:[a-z0-9\.]+\/)?([0-9]+)\/?$');
		if (regex.test(videoSrc)) {
			$(modal).find('.js-video-data-href').attr('data-href', videoSrc);
			FB.XFBML.parse();
			$(modal).find('.js-video-data-href').show();
			$(modal).find('.js-iframe-src').hide();
		} else {
			$(modal).find('.js-iframe-src').attr('src', videoSrc);
			$(modal).find('.js-iframe-src').show();
			$(modal).find('.js-video-data-href').hide();
		}

		$(modal).on('hidden.bs.modal', e => {
			$(e.currentTarget).find('.js-video-data-href').removeAttr('data-href');
			FB.XFBML.parse();
			$(e.currentTarget).find('.js-iframe-src').removeAttr('src');
		});
	}

	return {
		init
	};
})();