export default (function() {
	let _$selector;
	let _$popup;

	function init(selector, popup) {
		if ($(selector).length > 0 && $(popup).length > 0) {
			_$selector = selector;
			_$popup = popup;
			$(selector).off('click', toggleNav);
			$(selector).on('click', toggleNav);
		}
	}

	function toggleNav(e) {
		e.preventDefault();
		e.stopPropagation();
		if ($(_$popup).hasClass('is-open')) {
			$(_$popup).removeClass('is-open');
			$(document).off('click', clickOutside);
		} else {
			$(_$popup).addClass('is-open');
			$(document).on('click', clickOutside);
		}
	}

	function clickOutside(e) {
		const $target = $(e.target);
		if (!$target.closest(_$popup).length) {
			_$popup.removeClass('is-open');
			$(document).off('click', clickOutside);
		}
	}

	return {init};
})();