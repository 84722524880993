/* https://www.w3schools.com/js/js_cookies.asp */

export function setCookie(cname, cvalue, exdays) {
	let expires;

	if (exdays) {
		const date = new Date();
		date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
		expires = `expires=${date.toUTCString()}`;
	} else {
		expires = '';
	}

	document.cookie = `${cname}=${cvalue};${expires};path=/;samesite=strict;`;
}

export function getCookie(cname) {
	const name = `${cname}=`;
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}

	return '';
}

export function deleteCookie(cname) {
	setCookie(cname, '', -1);
}