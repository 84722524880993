import loader from 'modules/Loader';
import route from 'routes/templatePostsList';

export default (function() {
	let _request;
	let _filters;
	let _wrapper;

	function init(wrapper, filters) {
		if ($(wrapper).length > 0) {
			_wrapper = wrapper;
			_filters = filters;

			$(filters).find('a').click(handleClick);
			$(wrapper).find('.js-post-pagination').find('a').click(handleClick);
		}
	}

	function handleClick(e) {
		e.preventDefault();
		const url = $(e.target).attr('href');

		if (url) {
			loadPage(url);
		}
	}

	function loadPage(url, callback) {
		if (_request) {
			_request.abort();
		}
		$(_wrapper).find('.js-loader').addClass('is-loading');

		_request = $.get(url)
			.done((data) => {
				const newPage = $(data).find(_wrapper).html();
				$(_wrapper).html(newPage);
				route.init();
				// init(_wrapper, _filters);
				loader.init();
				history.pushState({url}, null, url);
				_request = null;
			}).fail((jqXHR) => {
				console.log(jqXHR.responseText);

			});
	}

	return {init};
})();