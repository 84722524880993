/* eslint-disable no-useless-escape, no-control-regex */
export default (function() {

	function getUrlParameter(url, param) {
		const results = new RegExp(`[\?&]${param}=([^&]*)`).exec(url);

		if (results == null){
			return null;
		} else {
			return results[1] || 0;
		}
	}

	function setUrlParameter(url, param, paramVal, order = null) {
		const key      = encodeURIComponent(param);
		const value    = encodeURIComponent(paramVal);

		const baseUrl  = url.split('?')[0];
		const newParam = `${key}=${value}`;

		let params     = `?${newParam}`;
		let urlQueryString;

		if (url.split('?')[1] == undefined) {
			urlQueryString = '';
		} else {
			urlQueryString = `?${url.split('?')[1]}`;
		}

		// If the query string exists, then build params from it
		if (urlQueryString) {
			const updateRegex = new RegExp('([\?&])' + key + '[^&]*');
			const removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

			// Remove param if value is empty
			if (typeof value === 'undefined' || value === null || value === '') {
				params = urlQueryString.replace(removeRegex, "$1");
				params = params.replace(/[&;]$/, "");

			// If param exists already, update it
			} else if (urlQueryString.match(updateRegex) !== null) {
				params = urlQueryString.replace(updateRegex, "$1" + newParam);

			// If there are no query strings
			} else if (urlQueryString == '') {
				params = '?' + newParam;

			// Otherwise, add it to the query string at the end and re-order
			} else {
				const newUrl      = urlQueryString + '&' + newParam;
				const urlParams   = newUrl.split('?')[1];
				const paramsArr   = urlParams.split('&');

				// Split current parameters as key/value array
				const keyValueMap = paramsArr.map(o => {
					const [key, value] = o.split('=');
					return {
						key,
						value
					};
				});

				// Sort current parameters by key based upon given order
				let sortedParams;
				if (order) {
					sortedParams = keyValueMap.sort((a, b) => {
						return order.indexOf(a.key) - order.indexOf(b.key);
					});
				} else {
					sortedParams = keyValueMap;
				}

				// Construct new parameter string
				const newParams = sortedParams.map(o => `${o.key}=${o.value}`).join('&');
				params          = '?' + newParams;
			}
		}

		// no parameter was set so we don't need the question mark
		params = params === '?' ? '' : params;

		return baseUrl + params;
	}

	return {
		getUrlParameter,
		setUrlParameter
	};
})();